import axios from 'axios'
import { AuthHelper } from '../helpers/AuthHelper'

export class DigitalReturnsService {
    static RETURN_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/returns'

    static async getReturns(currentStore: string): Promise<any> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        }
        try {
            const response = await axios.get<any>(
                `${this.RETURN_ENDPOINT}/storeNumber/${currentStore}`,
                config
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
