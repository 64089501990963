import Colors from '../../assets/Colors'

export const navigationBarStyles = {
  navbarButtonStyle: {
    fontSize: 14,
    fontFamily: 'Napa Sans',
    color: '#fff',
    margin: '0px 15px'
  },
  navBarMenuIcon: {
    color: Colors.napaYellow
  },
  navBarActiveButton: {
    color: Colors.napaYellow,
    fontWeight: 'bold',
    margin: '0px 12.3px'
  },
  navbarTitleStyle: {
    flexGrow: 1,
    display: { xs: 'none', sm: 'block' },
    textAlign: 'left',
    fontWeight: 'bold'
  },
  navBarContainer: {
    // display: 'flex'
    //marginBottom: 5
  },
  navBarDigital: {
    marginRight: 10,
    cursor: 'pointer',
    width: 24,
    height: 24
  },
  navBarLogo: {
    paddingRight: 10,
    cursor: 'pointer',
    width: 45
  },
  navBarMenuGroup: {
    // display: {
    //   xs: 'none',
    //   sm: 'block'
    // },
    // flex: 1,
    // flexFlow: 'row',
  },
  dialogTitle: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS'
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContent: {
    paddingBottom: '8px'
  },
  dialogAction: {
    padding: '16px'
  },
  signOutDialogButtons: {
    width: '194px'
  },
  doneButton: {
    width: '100%'
  },
  menuList: {
    '& .MuiPaper-root': {
      width: '240px',
      color: Colors.napaBlue
    },
    '& .MuiTypography-root': {
      fontWeight: '700'
    },
    marginTop: 2
  }
}
