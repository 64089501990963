import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import WhiteboardToggleMapStyles from './WhiteboardToggleMapStyles'
import language from '../../../../language/language';
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext';

type MapProps = {
    isMapViewActive: boolean;
    toggleMapView?: any;
    toggleListViewSwitch?: any;
};

const WhiteboardToggleMap: React.FC<MapProps> = ({
    isMapViewActive,
    toggleMapView,
    toggleListViewSwitch,
}) => {
    const { currentLanguage } = useContext(LanguageContext)
    return (
        <div>
            <Button
                onClick={toggleListViewSwitch}
                variant={!isMapViewActive ? 'outlined' : 'contained'} // Highlight active button
                color="inherit"
                disableElevation
                sx={{ ...WhiteboardToggleMapStyles.mapButton, borderRadius: '4px 0px 0px 4px', padding: '8px 20px' }}
                disabled={isMapViewActive}
                startIcon={<FormatListBulletedIcon htmlColor="#ADADAD" style={{ fontSize: '28px' }} />}
            >
                {(language as any)[currentLanguage].whiteboard}
            </Button>
            <Button
                onClick={toggleMapView}
                variant={isMapViewActive ? 'outlined' : 'contained'} // Highlight active button
                color="inherit"
                disableElevation
                sx={{ ...WhiteboardToggleMapStyles.mapButton, borderRadius: '0px 4px 4px 0px' }}
                disabled={!isMapViewActive}
                startIcon={<MapOutlinedIcon htmlColor="#ADADAD" style={{ fontSize: '32px' }} />}
            >
                {(language as any)[currentLanguage].map}
            </Button>
        </div>
    );
};

export default WhiteboardToggleMap;