import React, { useContext } from 'react';
import language from '../../../../language/language';
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext';
import { AutoDispatchInfoStyles } from './AutoDispatchInfoStyles';
import { Box, CircularProgress } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/WarningAmber';
import Colors from '../../../../assets/Colors';
import Countdown, {zeroPad} from 'react-countdown'

type AutoDispatchInfoProps = {
  timeElapsed: number;
  isBackGround?: boolean;
  invoiceDateTime: number;
};
    
    
type CountdownObject = {
  minutes: number
  seconds: number
  completed: boolean
}

const getColoredBackGround = (isBackGround: boolean, isError: boolean, content: JSX.Element, errorAndIcon = true) => {
  return isBackGround ? (
    <Box
      sx={
        isError && errorAndIcon
          ? AutoDispatchInfoStyles.autoDispatchTimeError
          : AutoDispatchInfoStyles.autoDispatchTime
      }>
      {errorAndIcon && <FiberManualRecord
        sx={
          isError
            ? AutoDispatchInfoStyles.autoDispatchInfoIconError
            : AutoDispatchInfoStyles.autoDispatchInfoIcon
        }
      />}
      {content}
    </Box>
  ) : (
    content
  );
};

export const AutoDispatchInfo = ({ timeElapsed, isBackGround = false, invoiceDateTime}: AutoDispatchInfoProps) => {
  const { currentLanguage } = useContext(LanguageContext);
  const oneMin = 60000;
  const fiveMin = 5 * oneMin;
  const timeElapsedInMin = (Date.now() - invoiceDateTime) / oneMin;
  const TimeElapsedInBlue = 4;
  const TimeElapsedInRed = 5;
  const TimeElapsedError = 5.5;
  const deadline = fiveMin + invoiceDateTime;

  const countdownRenderer = ({ minutes, seconds, completed} : CountdownObject) => {
    if (completed) {
      return !isBackGround ? (
        <Box sx={AutoDispatchInfoStyles.startAutoDispatchButton}>
          <CircularProgress size='1rem'/> &nbsp;
          {language[currentLanguage].dispatchingNow}...
        </Box>
      ) : (
        <Box sx={AutoDispatchInfoStyles.startAutoDispatchButton}>
          {language[currentLanguage].dispatchingNow}
        </Box>
      );
    } else {
      return <>
        <Box component="span" marginRight={0}>
          {language[currentLanguage].autoDispatchIn} &nbsp;
        </Box>
        <Box component="span" fontWeight="bold">
          <span style = {{color : (!isBackGround && minutes<1? Colors.errorBgColor : Colors.black)}}>{zeroPad(minutes)}:{zeroPad(seconds)} min</span>
        </Box>
      </>
      
    }
  };

  const getContent = () => {
    if (timeElapsedInMin < TimeElapsedError) {
      return (
        <Countdown date={deadline} renderer={countdownRenderer}/>
      );
    }

    return (
      <Box
        component="span"
        sx={AutoDispatchInfoStyles.autoDispatchInfoContainer}
        color={isBackGround ? Colors.black : Colors.errorBgColor}>
        {!isBackGround && <WarningIcon />} &nbsp;
        {language[currentLanguage].dispatchError}
      </Box>
    );
  };

  const isError = timeElapsedInMin >= TimeElapsedInBlue;
  return getColoredBackGround(isBackGround, isError, getContent(), !(!isBackGround && timeElapsedInMin >= TimeElapsedInRed && timeElapsedInMin <= TimeElapsedError));
};
