import Colors from '../../assets/Colors'

export const storeSelectorStyles = {
  currentStoreTypography: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: Colors.napaGreenDark
  },
  selectStoreTypography: {
    fontSize: 14,
    color: Colors.napaBlueLink
  }
}
