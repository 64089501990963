import { useQuery } from 'react-query'
import { TermsKeys } from '../keys'
import { TermsAndConditionsService } from '../../utilities/services/TermsAndConditionsService'
import { useContext } from 'react'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useTerms = () => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  return useQuery([TermsKeys.GetTerms], (context) => TermsAndConditionsService.getLatestTerms(), {
    onError: (_err) => {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].errorTnC,
        duration: 3000
      })
    },
    retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
  })
}
