import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Colors from '../../../../assets/Colors'
import React, { useContext } from 'react'
import { AppUser } from '../../../../utilities/services/UserManagementService'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

type DriverListProps = {
  drivers: AppUser[]
  setSelectedDriver: (driver: AppUser | null) => void
  loading: boolean
}

const DriverList = ({ drivers, setSelectedDriver, loading }: DriverListProps) => {
  const { currentLanguage } = useContext(LanguageContext)
  const columns = [
    {
      field: 'givenName',
      headerName: `${(language as any)[currentLanguage].firstName}`,
      minWidth: 200,
      editable: false,
    },
    {
      field: 'familyName',
      headerName: `${(language as any)[currentLanguage].lastName}`,
      minWidth: 200,
      editable: false
    },
    {
      field: 'username',
      headerName: `${(language as any)[currentLanguage].usernameText}*`,
      minWidth: 200,
      editable: false,
      flex: 1
    },
    {
      field: 'email',
      headerName: `${(language as any)[currentLanguage].email}`,
      minWidth: 200,
      editable: false,
      flex: 1
    },
    {
      field: 'enabled',
      headerName: `${(language as any)[currentLanguage].status}`,
      minWidth: 200,
      editable: false,
      flex: 1,
      renderCell: (params: any) => {
        return (
          //TODO: Switch disable on data grid currently not implemented, commented code for later use
          !params.row.enabled ? (
            <Typography
              sx={{
                fontStyle: 'italic',
                fontSize: '14px',
                color: '#33333'
              }}
            >
              {(language as any)[currentLanguage].userDisabled}
            </Typography>
          ) : (
            ''
          )
          // <FormControlLabel
          //   control={<IOSSwitch sx={{ m: 1 }} defaultChecked={params.row.enabled}/>}
          //   label={params.row.enabled ? "ACTIVE" : "DISABLED"}
          // />
        )
      }
    }
  ]

  const tableData = drivers.filter(user => user.userType !== 'superuser').map((driver): any => {
    const {
      familyName,
      givenName,
      email,
      username,
      phoneNumber,
      defaultStoreNumber,
      storeList,
      userType,
      enabled
    } = driver
      return {
        id: username,
        givenName,
        familyName,
        username,
        email,
        phoneNumber,
        defaultStoreNumber: defaultStoreNumber,
        storeList: storeList,
        userType,
        enabled
      }
  })

  const skeleton = (
    <Stack>
      <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
      <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
      <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
      <Skeleton variant={'text'} height={'52px'} animation={'wave'} />
    </Stack>
  )

  // const IOSSwitch = styled((props: SwitchProps) => (
  //   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  // ))(({ theme }) => ({
  //   width: 42,
  //   height: 26,
  //   padding: 0,
  //   '& .MuiSwitch-switchBase': {
  //     padding: 0,
  //     margin: 2,
  //     transitionDuration: '300ms',
  //     '&.Mui-checked': {
  //       transform: 'translateX(16px)',
  //       color: '#fff',
  //       '& + .MuiSwitch-track': {
  //         backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
  //         opacity: 1,
  //         border: 0,
  //       },
  //       '&.Mui-disabled + .MuiSwitch-track': {
  //         opacity: 0.5,
  //       },
  //     },
  //     '&.Mui-focusVisible .MuiSwitch-thumb': {
  //       color: '#33cf4d',
  //       border: '6px solid #fff',
  //     },
  //     '&.Mui-disabled .MuiSwitch-thumb': {
  //       color:
  //         theme.palette.mode === 'light'
  //           ? theme.palette.grey[100]
  //           : theme.palette.grey[600],
  //     },
  //     '&.Mui-disabled + .MuiSwitch-track': {
  //       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     boxSizing: 'border-box',
  //     width: 22,
  //     height: 22,
  //   },
  //   '& .MuiSwitch-track': {
  //     borderRadius: 26 / 2,
  //     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
  //     opacity: 1,
  //     transition: theme.transitions.create(['background-color'], {
  //       duration: 500,
  //     }),
  //   },
  // }));

  return loading ? (
    skeleton
  ) : (
    <Box>
      <Divider variant="fullWidth" />
      <DataGrid
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        disableColumnMenu
        columns={columns}
        rows={tableData}
        initialState={{
          sorting: {
            sortModel: [{ field: 'givenName', sort: 'asc' }]
          }
        }}
        disableVirtualization={process.env.NODE_ENV === 'test'}
        onSelectionModelChange={(itm) => {
          if(itm.length){
            const driver = tableData.find((driver) => driver.id === itm[0])
            setSelectedDriver({ ...driver, username: driver.id })
          }
        }}
        sx={{
          height: '600px',
          border: 0,
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '14px',
            fontFamily: '400',
            lineHeight: '15px',
            letterSpacing: '0.42px',
            color: '#707070'
          },
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
            color: '#33333'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
            color: Colors.napaBlue,
            fontWeight: 'bolder'
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent:focus': {
            fontWeight: 'bolder'
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              '&:nth-child(2n-1)': { backgroundColor: '#F4F4F4' },
              '&:nth-child(2n-1):hover': { backgroundColor: '#E5E7F3' },
              '&:nth-child(2n):hover': { backgroundColor: '#E5E7F3' }
            }
          },
          '& .MuiDataGrid-sortIcon': {
            color: Colors.napaBlue,
            outline: 'none'
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#E5E7F3'
          }
        }}
      />
    </Box>
  )
}

export default DriverList
