import { createContext, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useDigitalReturnsByStore } from '../../queries/DigitalReturns/useDigitalReturnsByStore'
import Colors from '../../assets/Colors'
import { digitalReturnsStyles } from './DigitalReturnsStyles'
import ReturnsList from './ReturnsList/ReturnsList'

export const ReturnsContext = createContext<ReturnsContextType>({
    setSelectedReturns: () => { },
    selectedReturns: ''
})

type ReturnsContextType = {
    selectedReturns: any
    setSelectedReturns: any
}

type ReturnsTab = 'pending' | 'completed'

const DigitalReturns = () => {
    const [selectedReturns, setSelectedReturns] = useState<any>(null)
    const [returnsToggle, setReturnsToggle] = useState<ReturnsTab>('pending')
    const { data: digitalReturns } = useDigitalReturnsByStore()

    return (
        <ReturnsContext.Provider
            value={{
                selectedReturns,
                setSelectedReturns
            }}>
            <Grid container spacing={2} sx={digitalReturnsStyles.gridContainer}>
                <Grid item xs={12} sx={digitalReturnsStyles.gridItem}>
                    <Grid xs={8} sx={digitalReturnsStyles.grid} item>
                        <Typography data-testid="digital_returns" sx={digitalReturnsStyles.returnsTitle}>
                            Returns
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    style={{
                        marginTop: '0px',
                        paddingTop: '20px'
                    }}>
                    <Button
                        onClick={() => {
                            setReturnsToggle('pending')
                        }}
                        sx={{
                            ...digitalReturnsStyles.pendingTabFont,
                            color: returnsToggle === 'pending' ? Colors.napaBlue : 'black',
                            fontWeight: returnsToggle === 'pending' ? 700 : 400,
                            borderBottom: returnsToggle === 'pending' ? 'solid' : 'none'
                        }}>
                        Pending
                    </Button>
                    <Button
                        onClick={() => {
                            setReturnsToggle('completed')
                        }}
                        sx={{
                            ...digitalReturnsStyles.completedTabFont,
                            color: returnsToggle === 'completed' ? Colors.napaBlue : 'black',
                            fontWeight: returnsToggle === 'completed' ? 700 : 400,
                            borderBottom: returnsToggle === 'completed' ? 'solid' : 'none'
                        }}>
                        Completed
                    </Button>
                </Grid>
                <ReturnsList
                    digitalReturns={digitalReturns}
                    returnsToggle={returnsToggle} />
            </Grid>
        </ReturnsContext.Provider>
    )
}

export default DigitalReturns