// const millisecondsInDay = 24 * 60 * 60 * 1000

// start date will be start of the day, drop time
export const makeStartDate = (date: number): number => {
  return new Date(date).setHours(0, 0, 0, 0) // should return 12:00:00:000 AM localtime
}

// end date will be end of the day, drop time and add a day
export const makeEndDate = (date: number): number => {
  return new Date(date).setHours(23, 59, 59, 999) // should return 11:59:59:990 PM localtime
}
