import axios from 'axios'
import { AuthHelper } from '../helpers/AuthHelper'

export type Vehicles = {
    make?: string
    vehicleName?: string
    vehicleVin: string
    year?: any
    model?: string
    licensePlate?: string
    color?: string
    lastOdometerReading?: any | null
    storeNumber?: string
    inspectionFrequency?: any | null
    createdBy?: string | undefined
    createdDate?: string
    obsoleteRecord?: Boolean
    lastInspectionDate?: string | null
    lastServiceDate?: string | null
}

export type AllVehicles = {
    vehicles: Vehicles[]
}

export class MyFleetService {
    static USER_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/vehicles'

    static async getAllVehicles(storeNumber: string): Promise<AllVehicles> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        }
        try {
            const response = await axios.get<AllVehicles>(`${this.USER_ENDPOINT}/storeNumber/${storeNumber}`, config)
            return response.data as AllVehicles
        }
        catch (error: any) {
            return Promise.reject(error.response);
        }
    }
    
    static async saveNewVehicle(vehicleRequest: Vehicles): Promise<Vehicles> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        }
        try {
            const response = await axios.post<Vehicles>(this.USER_ENDPOINT, vehicleRequest, config)
            return response.data;
        }
        catch (error: any) {
            return Promise.reject(error.response?.data?.message);
        }
    }
    
    static async disableVehicle(vehicleVin: string): Promise<void> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}`}
        }
        try {
            await axios.patch(`${this.USER_ENDPOINT}/${vehicleVin}/disable`,null, config)
            return
        }
        catch (error: any) {
            return Promise.reject(error.response?.data?.message);
        }
    }

    static async updateVehicleDetails(vehicleRequest?: Vehicles): Promise<Vehicles> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}`}
        }
        try {
            const response = await axios.patch<Vehicles>(`${this.USER_ENDPOINT}`, vehicleRequest, config)
            return response.data;
        }
        catch (error: any) {
            return Promise.reject(error.response?.data?.message);
        }
    }

    static async getInspectionReports(storeNumber?: string, vehicleVin?: string): Promise<any> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        }
        try {
            const response = await axios.get<any>(`${this.USER_ENDPOINT}/inspectionVehicleBrowser/${vehicleVin}`, config)
            return response.data;
        }
        catch (error: any) {
            return Promise.reject(error.response?.data?.message || error.response);
        }
    }
}
