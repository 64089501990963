import Colors from '../../assets/Colors'

export const signInStyles = {
  main: {
    margin: 'auto',
    alignContent: 'center'
  },
  logo: {
    height: 110,
    width: 105,
    marginTop: 150
  },
  alertMessage: {
    width: 1034,
    height: 60,
    radius: 5,
    padding: '16px 16px 24px 16px',
    gap: 12,
    background: '#FCEDEE',
    border: '1px solid #D61F28',
    boxShadow: '12px 12px 24px rgba(0, 0, 0, 0.2)',
    borderRadius: 5
  },

  alertMessageText: {
    font: 'napa-sans-roman',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0.48px',
    color: '#333333'
  },

  resetPasswordText: {
    font: 'napa-sans-roman',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#333333',
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0'
  },
  signInHeader: {
    color: '#333333',
    font: 'napa-sans-roman',
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '38px',
    letterSpacing: '0.48px',
    margin: '16px 5px 24px 5px',
    flex: 'none',
    order: '1',
    flexGrow: '0'
  },

  fieldEmail: {
    width: '330px',
    height: '25px',
    border: '0px solid #ADADAD',
    borderRadius: '4px',
    background: '#FFFFFF'
  },

  fieldPassword: {
    width: '330px',
    height: '25px',
    border: '0px solid #ADADAD',
    marginTop: '40px',
    borderRadius: '4px',
    background: '#FFFFFF'
  },

  checkboxContainer: {
    fontSize: '13px',
    color: Colors.black,
    font: 'napa-sans-roman',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '15px',
    alignItems: 'center',
    letterSpacing: '0.42px',
    margin: '65px 5px 15px 5px'
  },
  checkbox: {
    color: Colors.black
  },
  forgotPassword: {
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '400',
    color: Colors.napaBlueLink,
    font: 'napa-sans-roman',
    marginTop: 40,
    marginLeft: 5,
    textDecoration: 'none'
  },

  emailTextField: {
    borderRadius: '4px',
    margin: '24px 0px 24px 0px'
  },

  passwordTextField: {
    borderRadius: '4px',
    margin: '35px 0px 0px 0px'
  },

  errorEmail: {
    color: '#BA1B23',
    fontSize: '12px',
    margin: '5px 0px 10px 0px'
  },

  errorPassword: {
    color: '#BA1B23',
    fontSize: '12px',
    margin: '5px 0px 28px 0px'
  },

  newUserLabel: {
    width: '310px',
    margin: '19px auto 20px auto',
    font: 'napa-sans-roman',
    color: '#333333',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px'
  }
}
