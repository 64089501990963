import Colors from '../../../../assets/Colors'

export const invoiceDetailStyles = {
  invoiceContent: {
    padding: '16px'
  },
  renderInvoiceBox: {
    display: 'flex',
    paddingTop: '12px'
  },
  icon: {
    width: 20,
    height: 20
  },
  placeHold: {
    textTransform: 'capitalize',
    color: Colors.napaGrey,
    padding: '0 8px 0 0'
  },
  markDelivered: {
    textTransform: 'capitalize',
    color: Colors.napaGrey,
    borderRadius: 0,
    borderLeft: '1px solid ' + Colors.napaGrey,
    padding: '0 8px 0 8px'
  },
  removeButton: {
    textTransform: 'capitalize',
    color: Colors.napaRed3,
    borderRadius: 0,
    borderLeft: '1px solid ' + Colors.napaGrey,
    padding: '0 8px 0 8px'
  },
  closeButton: {
    padding: '0 8px 0 8px'
  },
  stopAutoDispatchText: {
    fontFamily: 'NAPA SANS',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '15px',
    marginBottom: '16px',
    marginLeft: '8px'
  },
  stopAutoDispatchTitleText: {
    fontFamily: 'NAPA SANS',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px',
    marginLeft: '8px'
  },
  stopAutoDispatchInput: {
    marginLeft: '8px'
  },
  stopAutoDispatchCheckBox: {
    margin: '0 8px 0 4px'
  },
  stopAutoDispatch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    background: Colors.napaGreyDisabled,
    border: '1px solid #C1C1C1',
    padding: '8px 8px',
    marginTop: '16px'
  },
  alertTitle: {
    fontSize: '14px',
    fontWeight: '700',
    color: Colors.napaGrey2
  },
  titleRight: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'right'
  },
  alertDescription: {
    fontSize: '12px',
    fontWeight: '400',
    color: Colors.napaGrey2
  },
  autoDispatchInfoContainer: {
    fontWeight: 'bold',
    fontSize: '17px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  InvoiceDetailsCloseButton: {
    width: '100%',
    marginTop: '16px',
    marginRight: '5px',
    height: '44px',
    textTransform: 'capitalize'
  },
  PrimaryButton: {
    width: '100%',
    marginTop: '16px'
  },
  doordashContact: {
    fontSize: '12px',
    textAlign: 'center',
    padding: '0px 35px 20px 35px',
    display: 'block'
  }
}
