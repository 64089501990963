import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Container,
  InputAdornment,
  IconButton
} from '@mui/material'
import logo from '../../../../assets/napa_logo.png'

import Colors from '../../../../assets/Colors'
import styles from './NewUserPasswordStyles'

import { useNavigate } from 'react-router-dom'
import { useState, useContext } from 'react'
import { AuthHelper } from '../../../../utilities/helpers/AuthHelper'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

const NewUserPassword = ({ user }: { user: any }) => {
  const navigate = useNavigate()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)

  const [viewPassword, setViewPassword] = useState({
    new: false,
    confirm: false
  })

  const togglePassword = (type: string) => {
    if (type === 'new') {
      setViewPassword({ ...viewPassword, new: !viewPassword.new })
    }
    if (type === 'confirm') {
      setViewPassword({ ...viewPassword, confirm: !viewPassword.confirm })
    }
  }

  const createNewPassword = async () => {
    try {
      await AuthHelper.completePasswordChallenge(user, newPassword)

      addSnack({
        severity: 'success',
        message: (language as any)[currentLanguage].successPassword,
        action: null,
        duration: 3000
      })
      navigate('/')
    } catch (error) {
      console.error(error)
      addSnack({
        severity: 'error',
        message: 'Unknown service error, please try again.',
        action: null,
        duration: 3000
      })
    }
  }
  const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

  const disableLogin =
    !newPassword ||
    newPassword !== confirmPassword ||
    newPassword.length < 8 ||
    !passwordRegex.test(newPassword)

  const iconColor = () => {
    if (!newPassword || !confirmPassword) {
      return Colors.napaGrey
    }

    if (newPassword.length > 8 || confirmPassword.length > 8) {
      return Colors.napaGreenDark
    }
  }

  return (
    <>
      <Container sx={styles.forgotPasswordContainer}>
        <Stack style={styles.forgotPasswordStack}>
          <Box>
            <img src={logo} alt={'napa logo'} style={styles.logo} />
          </Box>
          <Box>
            <Typography variant={'h4'} style={styles.header} data-testid="forgot-text">
            {(language as any)[currentLanguage].createPassword}
            </Typography>
          </Box>

          <Box
            width={{ xs: '300px', sm: '350px' }}
            component={'form'}
            noValidate
            sx={styles.submitContainer}
          >
            <TextField
              autoComplete="false"
              label={`${(language as any)[currentLanguage].newPassword}*`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => togglePassword('new')}>
                      {viewPassword.new ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={newPassword}
              id="new-password"
              margin="normal"
              data-testid="new-password"
              type={viewPassword.new ? 'text' : 'password'}
              inputProps={{ style: styles.input }}
              sx={{
                width: '330px',
                height: '48px',
                mb: '15px'
              }}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              autoComplete="false"
              type={viewPassword.confirm ? 'text' : 'password'}
              label={(language as any)[currentLanguage].confirmPassword}
              value={confirmPassword}
              data-testid="confirm-password"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => togglePassword('confirm')}>
                      {viewPassword.confirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ style: styles.input }}
              sx={{
                width: '330px',
                height: '48px',
                mb: '15px'
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              // onBlur={handleBlur}
            />
            <Box sx={styles.passwordContainer}>
              <Typography style={styles.passwordHeader} data-testid="forgot-text">
              {(language as any)[currentLanguage].passwordRequirements}
              </Typography>
              <Typography sx={styles.passwordText} data-testid="forgot-text">
                {newPassword && newPassword.length < 8 ? (
                  <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
                ) : (
                  <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: iconColor() }} />
                )}
                &nbsp; {(language as any)[currentLanguage].charatersLongText}
              </Typography>
              <Typography sx={styles.passwordText} data-testid="forgot-text">
                {newPassword && !passwordRegex.test(newPassword) ? (
                  <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
                ) : (
                  <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: iconColor() }} />
                )}
                &nbsp; {(language as any)[currentLanguage].alphaNumeric}
              </Typography>
            </Box>
            <Button
              onClick={createNewPassword}
              variant="contained"
              sx={styles.login}
              disabled={disableLogin}
              data-testid="create-password-button"
            >
              {(language as any)[currentLanguage].createSignInPassword}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  )
}

export default NewUserPassword
