import Colors from '../../../../assets/Colors'

export const editRouteStyles = {
  routeReviewTypography: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '22px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS',
    display: 'flex',
    padding: '0px 10px 10px'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-10px',
    padding: '16px 10px 0px'
  },
  totalStops:{
    color: Colors.napaBlack,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS',
    marginTop: '-30px'
  },
  invoiceDeliveryAddress: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoiceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '12px'
  },
  removeInvoiceType: {
    color: Colors.napaBlueLink,
    fontSize: '14px',
    paddingRight: '3px',
    marginTop: '2px',
    textTransform: 'none'
  },
  driversFormGrid: {
    display: 'flex',
  },
  driversForm: {
    m: 1,
    width: {
      xs: '120px',
      sm: '160px',
      md: '370px'
    },
    top: '-8px'
  },
  driverType: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'NAPA SANS',
    paddingBottom: '5px'
  },
  headerTitle:{
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  invoiceAddressLineType: {
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoice:{
    border: '1px solid rgb(193,193,193)',
    paddingLeft: 10,
    marginBottom: '25px',
    padding: '6px',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-18px'
  },
  returnStore:{
    background: Colors.napaGreyDisabled,
    padding: '6px',
    border: '1px solid rgb(193,193,193)',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-24px'
  },
  travelTime:{
    color: Colors.napaBlack,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS'
  },
  dialogActions: {
    padding: '16px',
    marginTop: '-20px'
  },
  editButton: {
    color: Colors.napaBlueLink,
    textTransform: 'none'
  },
  removeButtonBox: {
    display: 'flex',
    justifyContent: 'row',
    paddingRight: '10px'
  },
  invoiceNumber:{
    color: Colors.napaGrey2,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  RouteMapPreview: {
    top: '0px',
    display: 'flex',
    padding: '10px',
  },
  notesTitle: {
    color: Colors.black,
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  stickyBottom: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white', 
    padding: '10px'
  },
  renderAddressBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  accordionSummary: {
    height: '45px', 
    padding: '0 8px' 
  }
}
