import mapboxgl from 'mapbox-gl'
import { LegacyRef, MutableRefObject, useContext, useEffect, useMemo, useRef } from 'react'
import { styles } from './InvoicesMapViewStyles'
import { useInvoicesByStore } from '../../../../queries/Invoices/useInvoicesByStore'
import { RouteBuilderContext, RouteBuilderTab } from '../../RouteBuilder'
import { InvoicesResponse } from '../../../../utilities/services/DeliveryRouteService'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { addStoreMarker, createMarkerElement } from '../../../../components/MapView/MapMarkers'
import { useActiveDrivers } from '../../../../queries/Users/useActiveDrivers'
import { createRoot } from 'react-dom/client'
import { styles as mapViewStyles } from '../../../../screens/MapView/MapViewStyles'
import * as geolib from 'geolib'
import { Avatar, Box, Stack } from '@mui/material'

interface MarkerData {
  invoiceNo: string
  marker: mapboxgl.Marker
  selected: boolean
  priority: Number
}

const getMarkerElem = (
  invoice: InvoicesResponse,
  selectedInvoices: string[],
  invoices: InvoicesResponse[]
) => {
  const relInvoices = invoices.filter((el) => el.customerNumber === invoice.customerNumber)
  const sortedInvoices = relInvoices.sort((a, b) => {
    if (a.invoiceDateTime && b.invoiceDateTime) {
      return a.invoiceDateTime - b.invoiceDateTime
    }
    return 0
  })

  let isAnyInvoiceSelected = false // Flag to check if any invoice is selected

  sortedInvoices.forEach((relatedInvoice) => {
    const isInvoiceSelected = selectedInvoices
      .map((eachInvoice) => eachInvoice.toString())
      .includes(relatedInvoice.invoiceNumber)
    if (isInvoiceSelected) {
      isAnyInvoiceSelected = true
    }
  })

  const mostDelayedInvoice = sortedInvoices[0]

  const selectColorByPriority = getColorOfMarker(
    mostDelayedInvoice.deliveryPriorityInMins,
    convertInvoiceDateTimeInMins(mostDelayedInvoice.invoiceDateTime)
  )

  const className = isAnyInvoiceSelected
    ? 'marker marker-selected'
    : `marker marker-${selectColorByPriority}`

  return createMarkerElement({ className })
}

const getPopupContent = (
  invoice: InvoicesResponse,
  invoices: InvoicesResponse[] = [],
  selectedInvoices: number[]
) => {
  const popupContent = document.createElement('div')
  let popupContentString = ''
  popupContentString += `<h4>${invoice.deliveryAddress.name}<span class="phone-number">${
    invoice.deliveryAddress.phoneNumber || ''
  }</span></h4>
    <ul class="list-group">`
  const relInvoices = invoices?.filter((el) => el.customerNumber === invoice.customerNumber)
  relInvoices
    ?.sort((a, b) => a.invoiceDateTime - b.invoiceDateTime)
    .forEach((inv) => {
      const invoiceNumber = inv.invoiceNumber
      const isInvoiceSelected = selectedInvoices.includes(+invoiceNumber)
      const selectColorByPriority = getColorOfMarker(
        inv.deliveryPriorityInMins,
        convertInvoiceDateTimeInMins(inv.invoiceDateTime)
      )
      const markerClass = isInvoiceSelected
        ? 'marker-popup marker-selected'
        : `marker-popup marker-${selectColorByPriority}`

      const highlight = isInvoiceSelected ? 'invoice-button highlighted' : 'invoice-button'

      popupContentString += `<li class="${highlight}" data-invoice-number="${invoiceNumber}">
        <span class="${markerClass}"></span>
        Invoice #${invoiceNumber}
        </li>`
    })
  popupContentString += '</ul>'

  popupContent.innerHTML = popupContentString

  return popupContent
}

const getColorOfMarker = (priority: any, convertDate: any) => {
  let colorCode = ''
  if (!priority) return ''
  switch (true) {
    case priority <= 30:
      if (convertDate <= 19) {
        colorCode = 'unassignedOnTime'
      } else if (convertDate >= 20 && convertDate <= 30) {
        colorCode = 'unassignedWarning'
      } else if (convertDate > 30) {
        colorCode = 'unassignedDelayed'
      }
      break
    case priority > 30 && priority <= 45:
      if (convertDate <= 34) {
        colorCode = 'unassignedOnTime'
      } else if (convertDate >= 35 && convertDate <= 45) {
        colorCode = 'unassignedWarning'
      } else if (convertDate > 46) {
        colorCode = 'unassignedDelayed'
      }
      break
    case priority > 45 && priority <= 60:
      if (convertDate <= 49) {
        colorCode = 'unassignedOnTime'
      } else if (convertDate >= 50 && convertDate <= 60) {
        colorCode = 'unassignedWarning'
      } else if (convertDate > 61) {
        colorCode = 'unassignedDelayed'
      }
      break
    case priority > 60 && priority <= 75:
      if (convertDate <= 64) {
        colorCode = 'unassignedOnTime'
      } else if (convertDate >= 65 && convertDate <= 75) {
        colorCode = 'unassignedWarning'
      } else if (convertDate > 76) {
        colorCode = 'unassignedDelayed'
      }
      break
  }
  return colorCode
}

const convertInvoiceDateTimeInMins = (invoiceDateTime: any) => {
  const givenDate: Date = new Date(invoiceDateTime) // Replace with your given date
  const currentDate: Date = new Date()
  const elapsedTimeInMilliseconds = currentDate.getTime() - givenDate.getTime()
  // Convert milliseconds to seconds
  const elapsedTimeInSeconds = Math.floor(elapsedTimeInMilliseconds / 1000)
  // Convert seconds to minutes
  return Math.floor(elapsedTimeInSeconds / 60)
}

const driverInitialsElement = (user: any, selectedColor: string) => {
  const initials =
    user.username === 'DOORDASH' ? 'DD' : `${user.givenName[0]}${user.familyName[0]}`.toUpperCase()
  const elem = document.createElement('div')
  const root = createRoot(elem)
  root.render(
    <div style={{ ...mapViewStyles.driverInitialMarker, backgroundColor: selectedColor }}>
      {initials}
    </div>
  )
  return elem
}

const popupInvoiceListListener = (
  event: Event,
  invoice: InvoicesResponse,
  markers: MarkerData[]
) => {
  const clickedElement = event.target as HTMLElement
  const invoiceNumber = parseInt(clickedElement.getAttribute('data-invoice-number') || '')
  const selectedInvoiceElement = document.querySelector(`[title="${invoiceNumber}"]`)
  const markerSpan = clickedElement.querySelector('.marker-popup')

  if (!isNaN(invoiceNumber) && markerSpan && selectedInvoiceElement !== null) {
    const elValue = selectedInvoiceElement.textContent
    const markerData = markers.find((m) => m.invoiceNo === invoiceNumber.toString())
    if (elValue === String(invoiceNumber) && markerData) {
      markerData.selected = !markerData.selected
    }
  }

  return { selectedInvoiceElement, invoiceNumber }
}

type InvoicesMapViewProps = {
  currentTab: RouteBuilderTab
}

const MapView = ({ currentTab }: InvoicesMapViewProps) => {
  const mapContainer = useRef() as MutableRefObject<HTMLElement>
  const map = useRef<mapboxgl.Map | undefined>(undefined)
  const markers = useRef<MarkerData[]>([])
  const storeMarker = useRef<mapboxgl.Marker | null>(null)
  const { selectedInvoices, setSelectedInvoices, filteredInvoices, startDate, endDate } =
    useContext(RouteBuilderContext)
  const { data } = useInvoicesByStore(startDate, endDate)
  const { currentStore, storeAddress } = useContext(StoreContext)
  const isFirstTimeRender = useRef(true)
  const storeLocation: [number, number] = useMemo(() => {
    return [storeAddress?.longitude ?? (0 as number), storeAddress?.latitude ?? (0 as number)]
  }, [storeAddress])
  let bounds = new mapboxgl.LngLatBounds()
  const { data: activeDrivers } = useActiveDrivers()
  const availableColors = [
    '#8E5863',
    '#BB4800',
    '#3343A1',
    '#D0044A',
    '#CB0084',
    '#B400C8',
    '#700050',
    '#28009A',
    '#007396',
    '#06745B',
    '#2D1432'
  ]

  const invoicesUnassigned = data?.filter((invoice) => {
    return invoice.nssaInvoiceStatus === 'INVOICED'
  })

  const invoicesOnHold = data?.filter((invoice) => {
    return invoice.nssaInvoiceStatus === 'ON_HOLD'
  })

  const invoices = currentTab === 'unassignedInvoices' ? invoicesUnassigned : invoicesOnHold

  const generateRandomColor = () => {
    let randomColor
    do {
      randomColor = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .toUpperCase()}`
    } while (isExcludedColor(randomColor))
    return randomColor
  }

  function isExcludedColor(hexColor: string) {
    // Convert the hex color to RGB
    const red = parseInt(hexColor.slice(1, 3), 16)
    const green = parseInt(hexColor.slice(3, 5), 16)
    const blue = parseInt(hexColor.slice(5, 7), 16)
    // Calculate the saturation level of the color
    const max = Math.max(red, green, blue)
    const min = Math.min(red, green, blue)
    const delta = max - min
    const saturation = delta / max

    return (
      saturation < 0.5 &&
      (red >= 100 || green >= 100 || blue >= 100) &&
      !availableColors.includes(hexColor)
    )
  }

  useEffect(() => {
    if (map.current) return
    const [usCenterLng, usCenterLat] = [-98.5795, 39.8283]
    map.current =
      new mapboxgl.Map({
        container: mapContainer.current as HTMLElement,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: storeLocation && storeLocation[0] ? storeLocation : [usCenterLng, usCenterLat],
        zoom: 2
      }) || null
  })

  useEffect(() => {
    if (map.current) {
      const mapCanvas = map.current.getCanvas()
      if (mapCanvas) {
        mapCanvas.style.cursor = styles.mapCanvas.cursor
      }
    }
  }, [])

  useEffect(() => {
    if (map.current) {
      storeMarker.current = addStoreMarker('40px').setLngLat(storeLocation)?.addTo(map.current)
    }
  }, [map, currentStore, storeLocation])

  useEffect(() => {
    if (invoices) {
      let tempMarker: MarkerData[] = []
      markers.current.forEach((marker) => {
        const popup = marker.marker.getPopup()
        if (popup.getElement()) {
          // Get the invoice associated with the marker
          const invoice = invoices.find((inv) => inv.invoiceNumber === marker.invoiceNo)
          if (invoice) {
            const updatedContent = getPopupContent(invoice, invoices, selectedInvoices)
            // Remove the existing click event listeners from the popup
            popup.off('open')
            popup.off('close')
            popup.setDOMContent(updatedContent)
            // Add click event listener to each invoice item in the updated popup content
            const invoiceItems = updatedContent.querySelectorAll('.invoice-button')
            invoiceItems.forEach((item) => {
              item.addEventListener('click', (event) => {
                const clickedElement = event.currentTarget as HTMLElement
                const invoiceNumber = clickedElement.getAttribute('data-invoice-number')
                if (invoiceNumber) {
                  handleClickOnPopup(invoiceNumber)
                }
              })
            })
          }
          tempMarker.push(marker)
        } else {
          marker.marker.remove()
        }
      })
      markers.current = tempMarker
      if (map !== undefined) {
        getGeolocationCoordinates(invoices)
      }
    } else {
      markers.current.forEach((marker) => {
        marker.marker.remove()
      })
      bounds.extend(storeLocation)
      map.current && map.current.fitBounds(bounds, { padding: 50, duration: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, selectedInvoices, filteredInvoices, storeAddress, activeDrivers])

  const getGeolocationCoordinates = (invoices: any[]) => {
    if (storeAddress && activeDrivers) {
      activeDrivers?.forEach((user, index) => {
        const selectedColor =
          availableColors.length > index ? availableColors[index] : generateRandomColor()
        const MAX_DISTANCE_FROM_STORE = 400 // in meters
        if (
          user.userLocation &&
          user.userLocation.longitude <= 90 &&
          user.userLocation.latitude !== 0 &&
          Math.abs(user.userLocation.longitude) <= 90 &&
          geolib.getDistance(user.userLocation, storeLocation) > MAX_DISTANCE_FROM_STORE
        ) {
          const driverLocations = [user.userLocation.longitude, user.userLocation.latitude] as [
            number,
            number
          ]
          const driverInitials = driverInitialsElement(user, selectedColor)
          if (driverLocations) {
            bounds.extend(driverLocations)
            new mapboxgl.Marker(driverInitials).setLngLat(driverLocations).addTo(map.current!)
          }
        }
      })
    }

    invoices.forEach(async (invoice: any) => {
      try {
        const coordinate: [number, number] = [invoice.longitude, invoice.latitude]
        if (coordinate) {
          bounds.extend(coordinate)
          const el = getMarkerElem(invoice, selectedInvoices, invoices)
          const popupContent = getPopupContent(invoice, invoices, selectedInvoices)

          // Select invoices from popup
          const invoiceList = popupContent.querySelector('.list-group')
          if (invoiceList) {
            invoiceList.addEventListener('click', (event: Event) => {
              const { invoiceNumber } = popupInvoiceListListener(event, invoice, markers.current)
              handleClickOnPopup(invoiceNumber)
            })
          }

          const isInvoiceSelected = selectedInvoices
            .map((eachInvoice) => eachInvoice.toString())
            .includes(invoice.invoiceNumber)
          const isInvoiceDisplayed = filteredInvoices
            .map((eachInvoice) => eachInvoice.toString())
            .includes(invoice.invoiceNumber)

          if (map.current) {
            if (isInvoiceDisplayed) {
              const marker = new mapboxgl.Marker(el)
                .setLngLat(coordinate)
                .setPopup(
                  new mapboxgl.Popup({
                    className: 'custom-popup no-anchor',
                    closeOnClick: true,
                    closeButton: true,
                    offset: [0, -15]
                  }).setDOMContent(popupContent)
                )
                .addTo(map.current)

              if (marker) {
                const popup = marker.getPopup()
                popup.on('open', () => {
                  const popupElement = popup.getElement()
                  if (popupElement) {
                    const popupContentElement = document.querySelector('.custom-popup h4')
                    if (popupContentElement) {
                      popupContentElement.scrollIntoView({ behavior: 'auto', block: 'nearest' })
                    }
                  }
                  const createButton = document.querySelector(
                    '.MuiButton-primary'
                  ) as HTMLButtonElement
                  if (createButton) {
                    createButton.addEventListener('click', () => {
                      popup.remove()
                    })
                  }
                })
                markers.current = [
                  //To clear markers when re-render.
                  ...(markers.current as any[]),
                  {
                    invoiceNo: invoice.invoiceNumber,
                    marker: marker,
                    selected: isInvoiceSelected,
                    priority: invoice.deliveryPriorityInMins
                  }
                ]
              }
            }
          }
        }
      } catch (e: any) {
        console.error('Get geolocation coordinates from address failed. ', e.message)
      }
    })
    //if (isFirstTimeRender.current) {
    if (map.current && invoices.length > 0) {
      try {
        map.current.fitBounds(bounds, { padding: 50, duration: 0 })
        isFirstTimeRender.current = false
      } catch (e) {}
    }
    //}
  }

  const DriverIcons = () => {
    const today = new Date().toDateString()
    const filteredDriversForToday = activeDrivers?.filter(
      ({ userLocation }) =>
        userLocation &&
        userLocation.timestamp &&
        new Date(userLocation.timestamp).toDateString() === today
    )
    // Filter drivers to only include those with pending routes (assigned drivers only)
    const storeLocation: [number, number] = [
      storeAddress?.longitude as number,
      storeAddress?.latitude as number
    ]
    // Find drivers who are within or outside the pre-defined distance from the store, only for drivers with pending routes
    const MAX_DISTANCE_FROM_STORE = 400 // in meters
    const driversWithinDistance = filteredDriversForToday?.filter(
      (user) =>
        user.userLocation !== null &&
        geolib.getDistance(user.userLocation, storeLocation) <= MAX_DISTANCE_FROM_STORE
    )

    return (
      <Box position="absolute" top={0} left={0} padding={2} zIndex={2}>
        <Stack direction="row" spacing={-1}>
          {driversWithinDistance?.map((user, index) => {
            const selectedColor = '#001489' // blue for within distance, grey for outside distance
            const initials =
              user.username === 'DOORDASH'
                ? 'DD'
                : `${user.givenName[0]}${user.familyName[0]}`.toUpperCase()
            if (index < 4) {
              return (
                <Avatar key={index} sx={{ ...mapViewStyles.driverAvatar, bgcolor: selectedColor }}>
                  {initials}
                </Avatar>
              )
            } else if (index === 4) {
              return (
                <Avatar key={index} sx={{ ...mapViewStyles.driverAvatar, bgcolor: selectedColor }}>
                  +{driversWithinDistance.length - 4}
                </Avatar>
              )
            } else {
              return <></>
            }
          })}
        </Stack>
      </Box>
    )
  }

  const scrollToSelectedInvoice = (selectedInvoices: number[], invoiceNumber: any) => {
    const selectedInvoiceElement = document.querySelector(`[title="${invoiceNumber}"]`)
    if (selectedInvoiceElement) {
      selectedInvoiceElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      selectedInvoiceElement.classList.add('smooth-scroll')
    }
  }

  const handleClickOnPopup = (invoiceNumber: any) => {
    setSelectedInvoices((prevSelectedInvoices) => {
      const selectedInvoiceNumber = +invoiceNumber
      const isSelected = prevSelectedInvoices.includes(selectedInvoiceNumber)
      if (isSelected) {
        // Deselect the invoice
        return prevSelectedInvoices.filter(
          (selectedInvoice) => selectedInvoice !== selectedInvoiceNumber
        )
      } else {
        // Select the invoice
        const updatedSelectedInvoices = [...prevSelectedInvoices, selectedInvoiceNumber]
        setTimeout(() => {
          scrollToSelectedInvoice(updatedSelectedInvoices, invoiceNumber)
        }, 100) // Delay the scroll to allow popup to close
        return updatedSelectedInvoices
      }
    })
  }
  return (
    <div style={styles.mapContainer} ref={mapContainer as LegacyRef<HTMLDivElement>}>
      <div>
        <DriverIcons />
      </div>
    </div>
  )
}

export { getMarkerElem, getPopupContent, popupInvoiceListListener, getColorOfMarker }
export default MapView
