import React, { useContext } from 'react'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import language from '../../../../language/language'
import { Typography } from '@mui/material'
import thickDashIcon from '../../../../assets/thick-dash.svg'
import thinDashIcon from '../../../../assets/thin-dash.svg'

import { MapLegendStyles } from './MapLegendStyles'

type MapLegendProps = {
  showActualRouteLegend?: boolean
  showSuggestedRouteLegend?: boolean
  showBackToStoreRoutesLegend?: boolean
  showRouteDisclaimer?: boolean
}

const MapLegend = ({
  showActualRouteLegend,
  showSuggestedRouteLegend,
  showBackToStoreRoutesLegend,
  showRouteDisclaimer
}: MapLegendProps): JSX.Element => {
  const { currentLanguage } = useContext(LanguageContext)

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {showActualRouteLegend && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img src={thickDashIcon} alt={'Actual Route'} style={MapLegendStyles.icon} />
            <Typography sx={MapLegendStyles.actualRouteText}>
              {(language as any)[currentLanguage].actualRoute}
            </Typography>
          </div>
        )}
        {showSuggestedRouteLegend && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img src={thinDashIcon} alt={'Suggested Route'} style={MapLegendStyles.icon} />
            <Typography sx={MapLegendStyles.suggestedRouteText}>
              {(language as any)[currentLanguage].suggestedRoute}
            </Typography>
          </div>
        )}
        {showBackToStoreRoutesLegend && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={MapLegendStyles.backToStoreText}>
              {(language as any)[currentLanguage].backToStoreRoutes}
            </Typography>
          </div>
        )}
      </div>
      <div>
        {showRouteDisclaimer && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={MapLegendStyles.gpsDisclaimerText}>
              {(language as any)[currentLanguage].gpsDisclaimer}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default MapLegend
