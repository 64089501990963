import Colors from '../../../../assets/Colors'

const styles = {
  hr: {
    borderTop: '1px solid #E5E7F3'
  },
  userCardContainer: {
    margin: '10px',
    minWidth: '320px',
    backgroundColor: '#F4F4F4'
  },
  userCardHeaderTypography: {
    variant: 'h6' as const,
    color: '#001489',
    fontWeight: 600,
    textAlign: 'left' as const
  },
  userCardHeader: {
    paddingBottom: '16px'
  },
  userCardRouteInfo: {
    textAlign: 'left' as const
  },
  userCardRouteIdTypography: {
    fontWeight: '1000',
    display: 'flex',
    justifyContent: 'start',
    fontSize: '16px'

  },
  userCardDeliveryAddressTypography: {
    //weird typescript bug where text align doesnt accept strings as a valid arg
    fontWeight: 700,
    fontSize: '14px',
    textAlign: 'left' as const
  },
  userCardComplete: {
    textAlign: 'left' as const,
    color: Colors.napaGreenDark,
    fontWeight: 700,
    padding: '0px 8px'

  },
  userCardPending: {
    textAlign: 'left' as const,
    color: Colors.napaGrey4,
    fontWeight: 700,
    padding: '0px 8px'
  },
  noActiveRoutesBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  },
  accordionComplete: {
    backgroundColor: '#D6D6D6',
    marginBottom: '10px'
  },
  completedTypography: {
    fontSize: '14px',
    textAlign: 'left' as const
  },
  invoiceRouteStatusBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    marginLeft: '50px'
  },
  stopDeliveryAddress: {
    display: 'flex',
    flexDirection: 'row' as const,
    width: '100%'
  },
  partDetails: {
    color: Colors.napaGrey,
    fontSize: '12px',
    fontWeight: '400',
    textAlign: 'left' as const,
    padding: '8px'
  },
  accordianSummary: {
    '& .MuiAccordionSummary-content': {
      '&.Mui-expanded': {
        margin: '0px',
        marginTop: '8px'
      }
    }
  },
  accordianExpanded: {
    '&.MuiAccordionDetails-root': {
      paddingTop: '0px'
    }
  },
  lastCardContent: {
    '&.MuiCardContent-root': {
      paddingTop: '0px'
    }
  },
  thirdPartyGrid: {
    fontWeight: '400',
    marginBottom: '5px',
    fontSize: '12px',
    lineHeight: '15px',
    justifyContent: 'start',
    display: 'flex'
  },
  returnStoreContainer: {
    backgroundColor: Colors.white,
    margin: '0',
    padding: '16px',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '3px'
  },
  invoiceAddressLineType: {
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  returnStore: {
    background: Colors.white,
    textAlign: 'left'
  },
  invoiceDeliveryAddress: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'NAPA SANS',
    textAlign: 'left'
  },
  addressMargin: {
    marginTop: '10px'
  },
  arrivedAt: {
    color: Colors.napaGreenDark,
    display: 'flex',
    flexDirection: 'row' as const
  },
  checkIcon: {
    color: Colors.napaGreenDark,
    width: '12px',
    height: '12px',
    marginRight: '5px',
    marginTop: '3px'
  },
  delayTitle: {
    color: Colors.napaRed,
    fontSize: '12px'
  }
}

export default styles
