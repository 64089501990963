export enum UserKeys {
  GetUsers = 'users',
  GetActiveDrivers = 'active-drivers',
}

export enum DeliveryRouteKeys {
  GetStoreDeliveryRoutes = 'delivery-routes',
  GetUserDeliveryRoutes = 'user-routes'
}

export enum InvoiceKeys {
  GetInvoicesByStore = 'invoices'
}

export enum TermsKeys {
  GetTerms = 'terms',
  GetUserTerms = 'user-terms'
}

export enum MyFleetKeys {
  GetFleet = 'fleet',
  GetInspectionReport = 'inspection-report'
}

export enum ReturnsKeys {
  GetReturns = "digital-returns"
}
