import React, { useContext, useState } from 'react'
import { Modal, Box, Grid, Typography, IconButton, Button } from '@mui/material'
import { Clear, AttachFile } from '@mui/icons-material'
import { FileUploader } from 'react-drag-drop-files'
import Colors from '../../../../assets/Colors'
import { UserManagementService } from '../../../../utilities/services/UserManagementService'
import { AuthContext } from '../../../../utilities/contexts/AuthContext'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { LoadingButton } from '@mui/lab'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

const ImportUsers = ({ setIsOpen, isOpen }: any) => {
  const { currentUser } = useContext(AuthContext)
  const { currentLanguage } = useContext(LanguageContext)
  const [file, setFile] = useState<File | null>()
  const [hasFileUploaded, setHasFileUploaded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { addSnack } = useContext(SnackbarContext)
  const [showUserCreationModal, setUserCreationModal] = useState<boolean>(false)
  const [response, setResponse] = useState<String>('')

  const handleChange = (file: any) => {
    setFile(file)
    setHasFileUploaded(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setHasFileUploaded(false)
    setIsLoading(false)
  }

  const closeResultsModaal = () => {
    setUserCreationModal(false)
  }

  const handleImport = async () => {
    if (currentUser && file) {
      try {
        setIsLoading(true)
        const message: any = await UserManagementService.batchImportUsers(file)
        setResponse(message)
        setIsOpen(false)
        setHasFileUploaded(false)
        setUserCreationModal(true)
      } catch (e: any) {
        addSnack({
          severity: 'error',
          message: e.response.data.message,
          action: null,
          duration: 3000
        })
        setIsOpen(false)
      }
    }
    setIsLoading(false)
  }

  const dropArea = (
    <div style={dropAreaStyle}>
      <div style={dropAreaItem}>
        <AttachFile fontSize='medium' />
      </div>
      <Typography sx={dropAreaText}>{(language as any)[currentLanguage].csvUpload}</Typography>
      <Typography sx={dropAreaItem}>{(language as any)[currentLanguage].drag}</Typography>
    </div>
  )

  const handleOnDrop = (
    <div style={dropAreaStyle}>
      <div style={dropAreaItem}>
        <AttachFile fontSize='medium' />
      </div>
      <Typography sx={dropAreaText}>
        {file?.name}
      </Typography>
    </div>
  )

  return (
    <>
    <Modal sx={modalStyle} open={isOpen} onClose={handleClose}>
      <Grid container sx={container}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography sx={titleStyle}>{(language as any)[currentLanguage].importUser}</Typography>
          <IconButton onClick={handleClose}>
            <Clear fontSize='medium' />
          </IconButton>
        </Grid>
        <Box>
          <Typography sx={modalText}>
          {(language as any)[currentLanguage].csvFormat}
          </Typography>
        </Box>
        <Box sx={sampleCSVLink}>{(language as any)[currentLanguage].downloadFormat}</Box>
        <Box sx={{ margin: '20px 0', width: '100%' }}>
          {!hasFileUploaded ? (
            <FileUploader
              hoverTitle=' '
              children={dropArea}
              handleChange={handleChange}
              name='importDrivers'
              types={['CSV']}
              label='Upload'
            />) : (
            <FileUploader
              hoverTitle=''
              children={handleOnDrop}
              name='importDrivers'
              types={['CSV']}
              label='Upload'
            />
          )}
        </Box>
        <Button variant={'secondary'} sx={{ width: '45%', marginX: '9px' }} onClick={handleClose}>
        {(language as any)[currentLanguage].cancel}
        </Button>
        <LoadingButton loading={isLoading} sx={{ width: '45%', marginX: '9px' }} variant={'primary'} onClick={handleImport}>
        {(language as any)[currentLanguage].import}
        </LoadingButton>
      </Grid>
    </Modal>
    {showUserCreationModal && 
    <Modal sx={modalStyle}  open={showUserCreationModal} onClose={closeResultsModaal}>
        <Grid container={true} sx={container}>
            <Grid sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <Typography sx={titleStyle}>Users created</Typography>
                <IconButton onClick={closeResultsModaal}>
                    <Clear fontSize='medium'/>
                </IconButton>
            </Grid>
            <Box sx={{margin: '20px 0', width: '100%', overflow: 'auto', height: '250px'}}>
            <Typography sx={responseFormat}> {response}</Typography>
            </Box>
            <Button variant={'primary'} sx={{ marginX: '9px'}} onClick={closeResultsModaal}>
            {(language as any)[currentLanguage].close}
            </Button>
        </Grid>
    </Modal>}
    </>
  )
}

const modalStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  width: '100%'
}

const responseFormat = {
  whiteSpace: 'pre-line'
}

const container = {
  width: '395px',
  alignItems: 'center',
  borderRadius: '3px',
  padding: '17px',
  backgroundColor: Colors.white
}

const titleStyle = {
  height: '32px',
  fontFamily: 'NAPA SANS',
  fontWeight: '700',
  fontSize: '25px',
  lineHeight: '32px',
  letterSpacing: '0.4px',
  color: Colors.napaBlue
}

const dropAreaStyle = {
  display: 'flex',
  flexFlow: 'column nowrap',
  border: '1.5px dashed #ADADAD',
  alignItems: 'center',
  paddingTop: '40px',
  paddingBottom: '10px'
}

const modalText = {
  fontFamily: 'NAPA SANS',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '20px',
  color: Colors.napaGrey2
}

const sampleCSVLink = {
  fontFamily: 'NAPA SANS',
  fontWeight: '400',
  fontSize: '16px',
  color: Colors.napaBlueLink,
  marginTop: '10px'
}

const dropAreaText = {
  fontFamily: 'NAPA SANS',
  fontWeight: '700',
  fontSize: '16px',
  color: Colors.napaBlueLink
}

const dropAreaItem = {
  fontFamily: 'NAPA SANS',
  fontWeight: '400',
  fontSize: '12px',
  color: Colors.napaGrey
}

export default ImportUsers
