import Colors from '../../../../assets/Colors'

export const routeListStyles = {
  routeAccordionAvatar: {
    border: '0.1px dashed #ADADAD',
    margin: '8px',
    marginRight: '14px'
  },
  routeAccordionIconButton: {
    margin: '8px',
    marginRight: '14px',
    backgroundColor: 'white',
    border: '0.1px dashed #ADADAD'
  },
  invoiceCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  noRoutesCard: {
    borderWidth: '1px',
    boxShadow: 'none',
    backgroundColor: '#F4F4F4',
    width: {
      xs: '100%'
    },
    maxHeight: '84px'
  },
  noRoutesCardContent: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  noRoutes: {
    color: '#474747',
    fontSize: '16px',
    fontWeigth: '400',
    lineHeight: '18px',
    textAlign: 'center',
    letterSpacing: '0.48px',
    display: 'block'
  },
  noRoutesBold: {
    color: '#474747',
    fontSize: '18px',
    fontWeigth: '700',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.48px',
    display: 'block'
  },
  routeTableCell: {
    fontFamily: 'NAPA SANS',
    fontWeight: 400,
    fontSize: '14px',
  },
  editRouteButton: {
    textTransform: 'none',
    color: Colors.napaBlueLink
  },
  menuList: {
    '& .MuiPaper-root': {
      width: '240px',
      color: Colors.black
    },
    '& .MuiTypography-root': {
      fontWeight: '400'
    },
    marginTop: 1
  },
  filtersBox: {
    display: 'flex',
    marginTop: '15px'
  },
  filterText: { 
    display: 'flex', 
    justifyContent: 'start', 
    marginBottom: '10px', 
    marginTop: '10px' 
  },
  filterButton: { 
    display: 'flex', 
    backgroundColor: Colors.napaGreyDisabled, 
    borderRadius: '50px', 
    padding: '8px 16px' 
  },
  statusText: { 
    fontSize: '14px', 
    color: Colors.napaBlack, 
    textTransform: 'none' 
  }
}
