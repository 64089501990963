import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Container,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Divider
} from '@mui/material'
import logo from '../../assets/napa_logo.png'

import resetPasswordStyles from './resetPasswordStyles'
import Colors from '../../assets/Colors'

import { useLocation, useNavigate } from 'react-router-dom'
import React, { useState, useContext } from 'react'
import { AuthHelper } from '../../utilities/helpers/AuthHelper'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { navigationBarStyles } from '../../components/NavigationBar/navigationBarStyles'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import { SupportedLanguage } from '../../language/LanguageTypes'

type Location = { state: { email?: string } }

type menuItem = {
  menuName: string | null | undefined
  icon: JSX.Element
  onClick?: (arg0: any) => void
}

const ResetPassword = () => {
  const location = useLocation() as Location
  const navigate = useNavigate()
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null)
  const { isFrenchEnabled } = useFlags()

  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const { addSnack } = useContext(SnackbarContext)
  const [error, setError] = useState('')
  const [viewPassword, setViewPassword] = useState({
    new: false,
    confirm: false
  })

  const togglePassword = (type: string) => {
    if (type === 'new') {
      setViewPassword({ ...viewPassword, new: !viewPassword.new })
    }
    if (type === 'confirm') {
      setViewPassword({ ...viewPassword, confirm: !viewPassword.confirm })
    }
  }

  const email = location.state?.email

  if (!email) {
    navigate('/')
  }

  const sendAnotherCode = async () => {
    try {
      await AuthHelper.forgotPassword(email as string)
      addSnack({
        severity: 'success',
        message: (language as any)[currentLanguage].mailSentToMail,
        action: null,
        duration: 3000
      })
    } catch (e) {
      addSnack({
        severity: 'error',
        message: 'Unknown service error, please try again.',
        action: null,
        duration: 3000
      })
    }
  }

  const forgotPasswordSubmit = async () => {
    try {
      await AuthHelper.forgotPasswordSubmit(email, code, newPassword)

      addSnack({
        severity: 'success',
        message: (language as any)[currentLanguage].successPassword,
        action: null,
        duration: 3000
      })
      navigate('/')
    } catch (error) {
      setError(error as string)
      if (error !== 'CodeMismatchException') {
        addSnack({
          severity: 'error',
          message: 'Unknown service error, please try again.',
          action: null,
          duration: 3000
        })
      }
    }
  }
  const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

  const disableLogin =
    !code ||
    !newPassword ||
    newPassword !== confirmPassword ||
    newPassword.length < 8 ||
    !passwordRegex.test(newPassword)

  const iconColor = () => {
    if (!newPassword || !confirmPassword) {
      return Colors.napaGrey
    }

    if (newPassword.length > 8 || confirmPassword.length > 8) {
      return Colors.napaGreenDark
    }
  }

  const handleLangChange = (value: SupportedLanguage) => {
    setAnchorElLanguage(null)
    changeLanguage(value)
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  const menus = [
    {
      menuName: '',
      icon: (
        <>
          <LanguageIcon sx={navigationBarStyles.navBarMenuIcon} />
          <KeyboardArrowDownSharpIcon sx={navigationBarStyles.navBarMenuIcon} />
        </>
      ),
      onClick: handleLanguageChange
    }
  ]

  const LanguageChange = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        defaultValue={currentLanguage}
        onClose={handleCloseLanguage}
        sx={navigationBarStyles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleLangChange('en')}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => handleLangChange('fr')}>
          <ListItemText>French</ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isFrenchEnabled &&
          menus.map((menu: menuItem) => (
            <Button
              sx={{
                ...navigationBarStyles.navbarButtonStyle,
                fontWeight: 'bold',
                textTransform: 'none'
              }}
              startIcon={menu.icon}
              onClick={menu.onClick}>
              {menu.menuName}
            </Button>
          ))}
      </div>
      <LanguageChange />
      <Container sx={resetPasswordStyles.forgotPasswordContainer}>
        <Stack style={resetPasswordStyles.forgotPasswordStack}>
          <Box>
            <img src={logo} alt={'napa logo'} style={resetPasswordStyles.logo} />
          </Box>
          <Box>
            <Typography variant={'h4'} style={resetPasswordStyles.header} data-testid="forgot-text">
              {(language as any)[currentLanguage].resetPassword}
            </Typography>
          </Box>
          <Box>
            <Typography style={resetPasswordStyles.subheader} data-testid="reset-text">
              {(language as any)[currentLanguage].authenticationCode}{' '}
              <span style={{ color: Colors.napaBlue }}> {location.state?.email} </span>{' '}
              {(language as any)[currentLanguage].changeYourPassword}
            </Typography>
          </Box>
          <Box
            width={{ xs: '300px', sm: '350px' }}
            component={'form'}
            noValidate
            sx={resetPasswordStyles.submitContainer}>
            <TextField
              error={error === 'CodeMismatchException'}
              autoComplete="new-password"
              label={(language as any)[currentLanguage].codeText}
              value={code}
              data-testid="code"
              margin="normal"
              inputProps={{ style: resetPasswordStyles.input }}
              sx={{
                width: '330px',
                height: '48px',
                margin: 0,
                mb: '15px'
              }}
              helperText=""
              onChange={(e) => setCode(e.target.value)}
            />
            {error && (
              <Typography sx={resetPasswordStyles.message}>
                {error === 'CodeMismatchException'
                  ? (language as any)[currentLanguage].invalidCode
                  : ''}
              </Typography>
            )}
            <Button
              onClick={sendAnotherCode}
              sx={resetPasswordStyles.submit}
              data-testid="send-button">
              {(language as any)[currentLanguage].sendAnotherCode}
            </Button>

            <TextField
              autoComplete="new-password"
              label={`${(language as any)[currentLanguage].newPassword}*`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => togglePassword('new')}>
                      {viewPassword.new ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={newPassword}
              id="new-password"
              margin="normal"
              data-testid="new-password"
              type={viewPassword.new ? 'text' : 'password'}
              inputProps={{ style: resetPasswordStyles.input }}
              InputLabelProps={{ style: { color: Colors.napaGrey } }}
              sx={{
                width: '330px',
                height: '48px',
                mb: '15px',
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': { borderColor: Colors.napaBlue }
                },
                '& .MuiOutlinedInput-root:hover': {
                  '& > fieldset': { borderColor: Colors.napaBlue }
                }
              }}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              autoComplete="new-password"
              type={viewPassword.confirm ? 'text' : 'password'}
              label={(language as any)[currentLanguage].confirmPassword}
              value={confirmPassword}
              data-testid="confirm-password"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => togglePassword('confirm')}>
                      {viewPassword.confirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ style: resetPasswordStyles.input }}
              InputLabelProps={{ style: { color: Colors.napaGrey } }}
              sx={{
                width: '330px',
                height: '48px',
                mb: '15px',
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': { borderColor: Colors.napaBlue }
                },
                '& .MuiOutlinedInput-root:hover': {
                  '& > fieldset': { borderColor: Colors.napaBlue }
                }
              }}
              onChange={(e) => setconfirmPassword(e.target.value)}
              // onBlur={handleBlur}
            />
            <Box sx={resetPasswordStyles.passwordContainer}>
              <Typography style={resetPasswordStyles.header} data-testid="forgot-text">
                {(language as any)[currentLanguage].passwordRequirements}
              </Typography>
              <Typography sx={resetPasswordStyles.passwordText} data-testid="forgot-text">
                {newPassword && newPassword.length < 8 ? (
                  <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
                ) : (
                  <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: iconColor() }} />
                )}
                &nbsp; {(language as any)[currentLanguage].charatersLongText}
              </Typography>
              <Typography sx={resetPasswordStyles.passwordText} data-testid="forgot-text">
                {newPassword && !passwordRegex.test(newPassword) ? (
                  <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
                ) : (
                  <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: iconColor() }} />
                )}
                &nbsp;{(language as any)[currentLanguage].alphaNumeric}
              </Typography>
            </Box>
            <Button
              onClick={forgotPasswordSubmit}
              variant="contained"
              sx={resetPasswordStyles.login}
              disabled={disableLogin}
              data-testid="login-button">
              {(language as any)[currentLanguage].resetMyPassword}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  )
}

export default ResetPassword
