import React, { ReactElement } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { datePickerAsNumberStyles } from './DatePickerAsNumberStyles'

type DateFilterProps = {
  label: string
  value: number
  onChange: (date: number) => void
}

const DatePickerAsNumber = ({
  label,
  value,
  onChange
}: DateFilterProps): ReactElement => {
  return (
    <div style={datePickerAsNumberStyles.datePickerStyle}>

      <DatePicker
      label={label}
      value={new Date(value)}
      onChange={(date: Date | null) =>
        onChange(date ? date.getTime() : new Date().getTime())
      }
      slotProps={{ textField: { size: 'small' } }}
    />
    </div>
  )
}

export default DatePickerAsNumber
