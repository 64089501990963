import Colors from "../../../../assets/Colors";

export const viewReportStyles = {
  viewReportModalContainer: {
    width: '640px',
    height: '648px',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    overflow: 'auto'
  },
  modalTitle: {
    height: '32px',
    fontFamily: 'NAPA SANS',
    fontWeight: '800',
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: Colors.napaBlue,
  },
  gridDisplay: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  viewReportModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  buttonContainer: {
    display: 'flex',
    paddingTop: "10px"
  },
  redButton: {
    height: 27,
    borderRadius: '3px',
    border: 'solid',
    width: '80%',
    color: Colors.napaRed,
    backgroundColor: Colors.white,
    borderWidth: '1px',
    borderColor: Colors.napaRed,
    alignItems: 'center',
    justifyContent: 'center'
  },
  redButtonSelected: {
    height: 27,
    borderRadius: '3px',
    border: 'solid',
    width: '80%',
    color: Colors.napaRed,
    backgroundColor: Colors.napaRed2,
    borderWidth: '2px',
    borderColor: Colors.napaRed,
    alignItems: 'center',
    justifyContent: 'center'
  },
  yellowButton: {
    height: 27,
    borderRadius: '3px',
    border: 'solid',
    width: '80%',
    borderWidth: '1px',
    backgroundColor: Colors.white,
    borderColor: Colors.napaYellow3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  yellowButtonSelected: {
    height: 27,
    borderRadius: '3px',
    border: 'solid',
    width: '80%',
    borderWidth: '2px',
    backgroundColor: Colors.napaYellow1,
    borderColor: Colors.napaYellow3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  greenButton: {
    height: 27,
    borderRadius: '3px',
    border: 'solid',
    width: '80%',
    borderWidth: '1px',
    backgroundColor: Colors.white,
    borderColor: Colors.napaGreenDark,
    alignItems: 'center',
    justifyContent: 'center'
  },
  greenButtonSelected: {
    height: 27,
    borderRadius: '3px',
    border: 'solid',
    width: '80%',
    borderWidth: '2px',
    backgroundColor: Colors.napaGreenLight,
    borderColor: Colors.napaGreenDark,
    alignItems: 'center',
    justifyContent: 'center'
  },
  redText: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.napaGrey2,
    textTransform: 'initial'
  },
  yellowText: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.napaGrey2,
    textTransform: 'initial'
  },
  greenText: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.napaGrey2,
    textTransform: 'initial'
  },
  boldText: {
    fontSize: 12,
    fontWeight: '700',
    color: Colors.napaGrey2,
    textTransform: 'initial'
  },
  fontBold: {
    fontWeight: '700'
  },
  fontNormal: {
    fontWeight: '400'
  },
  titleNormal: {
    fontSize: 14,
    color: Colors.napaGrey2
  },
  odometerTitle: {
    fontSize: 16,
    color: Colors.napaBlack
  },
  odometerInput: {
    fontSize: 16,
    color: Colors.napaBlack
  },
  titleSmall: {
    fontSize: 12,
    color: Colors.napaGrey2
  },
  closeButton: {
    fontSize: 16,
    color: Colors.napaBlueLink,
    fontWeight: '400',
    textTransform: 'initial'
  },
  inspectionHeader: {
    fontSize: 16,
    color: Colors.napaGrey2,
    fontWeight: '700'
  },
  inspectionHeaderDivider: {
    paddingLeft: "8px"
  },
  printBtn: {
    fontSize: "14px",
    color: Colors.napaGrey2,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.48px",
    cursor: "pointer",
    paddingLeft: "14px"
  },
  printIcon: {
    width: '24px', 
    height: '24px',
    paddingLeft: '5px',
    cursor: "pointer"
  }
}