import Colors from '../../../../assets/Colors'

export const MapLegendStyles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  actualRouteText: {
    width: '80px',
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'NAPA SANS',
    opacity: 0.7
  },
  suggestedRouteText: {
    width: '160px',
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'NAPA SANS',
    opacity: 0.7
  },
  backToStoreText: {
    width: '110px',
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'NAPA SANS',
    opacity: 0.7
  },
  legendText: {
    width: '160px',
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'NAPA SANS',
    opacity: 0.7
  },
  gpsDisclaimerText: {
    paddingTop: '3px',
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'NAPA SANS',
    opacity: 0.7
  },
  icon: {
    width: '30px',
    paddingRight: '5px',
  }
}
