import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Container,
  InputAdornment,
  IconButton,
  Divider,
  MenuItem,
  ListItemText,
  Menu
} from '@mui/material'
import logo from '../../assets/napa_logo.png'
import styles from './CompleteRegistrationStyles'
import { useNavigate } from 'react-router-dom'
import React, { useState, useContext } from 'react'
import { AuthHelper } from '../../utilities/helpers/AuthHelper'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import NewUserPassword from './components/NewUserPassword/NewUserPassword'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import { navigationBarStyles } from '../../components/NavigationBar/navigationBarStyles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SupportedLanguage } from '../../language/LanguageTypes'

type menuItem = {
  menuName: string | null | undefined
  icon: JSX.Element
  onClick?: (arg0: any) => void
}

const CompleteRegistration = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null)
  const [cognitoUser, setCognitoUser] = useState(null)
  const [viewPassword, setViewPassword] = useState(false)
  const { isFrenchEnabled } = useFlags()

  const togglePassword = () => setViewPassword(!viewPassword)

  const disableUsernamePassword = () => {
    if (!email || !password) {
      return true
    }
  }

  const verifyPassword = async () => {
    try {
      await AuthHelper.signIn({ username: email, password })
      navigate('/')
    } catch (error: any) {
      if (error.message === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(error.user)
      } else {
        addSnack({
          severity: 'error',
          message: error.message,
          action: error.action,
          duration: 3000
        })
      }
    }
  }

  const handleLangChange = (value: SupportedLanguage) => {
    setAnchorElLanguage(null)
    changeLanguage(value)
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  const menus = [
    {
      menuName: '',
      icon: (
        <>
          <LanguageIcon sx={navigationBarStyles.navBarMenuIcon} />
          <KeyboardArrowDownSharpIcon sx={navigationBarStyles.navBarMenuIcon} />
        </>
      ),
      onClick: handleLanguageChange
    }
  ]

  const LanguageChange = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        defaultValue={currentLanguage}
        onClose={handleCloseLanguage}
        sx={navigationBarStyles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleLangChange('en')}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => handleLangChange('fr')}>
          <ListItemText>French</ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  if (cognitoUser) return <NewUserPassword user={cognitoUser} />

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isFrenchEnabled &&
          menus.map((menu: menuItem) => (
            <Button
              sx={{
                ...navigationBarStyles.navbarButtonStyle,
                fontWeight: 'bold',
                textTransform: 'none'
              }}
              startIcon={menu.icon}
              onClick={menu.onClick}>
              {menu.menuName}
            </Button>
          ))}
      </div>
      <LanguageChange />
      <Container sx={styles.forgotPasswordContainer}>
        <Stack style={styles.forgotPasswordStack}>
          <Box>
            <img src={logo} alt={'napa logo'} style={styles.logo} />
          </Box>
          <Box>
            <Typography style={styles.header} data-testid="new-text">
              {(language as any)[currentLanguage].newUser}
            </Typography>
          </Box>
          <Box>
            <Typography style={styles.subheader} data-testid="reset-text">
              {(language as any)[currentLanguage].tempPassword}
            </Typography>
          </Box>
          <Box
            width={{ xs: '300px', sm: '350px' }}
            component={'form'}
            noValidate
            sx={styles.submitContainer}>
            <TextField
              autoComplete="false"
              label={`${(language as any)[currentLanguage].emailUsername}*`}
              value={email}
              margin="normal"
              inputProps={{ style: styles.input }}
              sx={{
                width: '330px',
                height: '48px',
                mb: '15px'
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              autoComplete="false"
              type={viewPassword ? 'text' : 'password'}
              label={`${(language as any)[currentLanguage].temporaryPassword}*`}
              value={password}
              margin="normal"
              data-testid="code"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => togglePassword()}>
                      {viewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ style: styles.input }}
              sx={{
                width: '330px',
                height: '48px',
                mb: '15px'
              }}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              onClick={verifyPassword}
              variant="contained"
              sx={styles.login}
              disabled={disableUsernamePassword()}>
              {(language as any)[currentLanguage].completeRegistration}
            </Button>

            <div style={styles.newUserLabel}>
              <Divider style={styles.newUserLabel}>
                {(language as any)[currentLanguage].existingAccount}
              </Divider>
            </div>
            <div>
              <Button variant="secondary" data-testid="signin-btn" onClick={() => navigate('/')}>
                {(language as any)[currentLanguage].signIn}
              </Button>
            </div>
          </Box>
        </Stack>
      </Container>
    </>
  )
}

export default CompleteRegistration
