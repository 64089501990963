import { useContext, useEffect, useState } from 'react'
import { Button, Grid, IconButton, Modal, TextField, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { MyFleetService } from '../../../../utilities/services/MyFleetService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { updateVehicleStyles } from './updateVehicleStyles'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

const UpdateVehicle = ({
    openUpdateModal,
    setOpenUpdateModal,
    vehicleDetails,
    setOdometerEdited,
    updateName
}: any
) => {
    const { addSnack } = useContext(SnackbarContext)
    const { currentLanguage } = useContext(LanguageContext)
    const [odometer, setOdometer] = useState<string>('')
    const [odometerError, setOdometerError] = useState<string>('')
    const blockInvalidChar = (event: { key: string; preventDefault: () => any }) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

    useEffect(() => {
        if (vehicleDetails.lastOdometerReading) {
            setOdometer(vehicleDetails.lastOdometerReading)
        }
    }, [vehicleDetails.lastOdometerReading])

    const validateFields = () => {
        let validate = true;
        if (!odometer) {
            setOdometerError((language as any)[currentLanguage].errorVehicle)
            validate = false
        }
        return validate;
    }

    const handleUpdateVehicle = async () => {
        if (!validateFields()) {
            return false
        }
        else {
            try {
                const vehicleRequest = {
                    make: vehicleDetails.make,
                    vehicleName: updateName || vehicleDetails.vehicleName,
                    vehicleVin: vehicleDetails.vehicleVin,
                    year: vehicleDetails.year,
                    model: vehicleDetails.model,
                    licensePlate: vehicleDetails.licensePlate,
                    color: vehicleDetails.color,
                    lastOdometerReading: odometer,
                    storeNumber: vehicleDetails.storeNumber,
                    inspectionFrequency: vehicleDetails.inspectionFrequency,
                    createdBy: vehicleDetails.createdBy,
                    createdDate: vehicleDetails.createdDate,
                    obsoleteRecord: vehicleDetails.obsoleteRecord,
                    lastInspectionDate: vehicleDetails.lastInspectionDate,
                    lastServiceDate: vehicleDetails.lastServiceDate
                }

                await MyFleetService.updateVehicleDetails(vehicleRequest);
                addSnack({
                    severity: 'success',
                    message: (language as any)[currentLanguage].successUpdated,
                    action: null,
                    duration: 3000
                })
                setOpenUpdateModal(false)
                setOdometerEdited(odometer)
            }
            catch (e: any) {
                addSnack({
                    severity: 'error',
                    message: e,
                    action: null,
                    duration: 3000
                })
            }
        }
    }

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
            <Modal
                open={openUpdateModal}
                onClose={() => setOpenUpdateModal(false)}
                sx={updateVehicleStyles.viewReportModal}
                data-testid={'close_modal'}>
                <Grid container sx={updateVehicleStyles.viewReportModalContainer}>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={updateVehicleStyles.modalTitle}>{(language as any)[currentLanguage].odometer}</Typography>
                        <IconButton onClick={() => setOpenUpdateModal(false)} sx={updateVehicleStyles.clearButton} data-testid={'close_button'}>
                            <ClearIcon fontSize="medium" />
                        </IconButton>
                    </Grid>
                    <TextField
                        sx={updateVehicleStyles.textInput}
                        inputProps={{ 'data-testid': 'input_odometer' }}
                        type='number'
                        placeholder={(language as any)[currentLanguage].odometer}
                        error={!!odometerError}
                        value={odometer}
                        onChange={(odometer) => setOdometer(odometer.target.value)}
                        onKeyDown={blockInvalidChar}
                        onKeyUp={() => setOdometerError('')}
                    />
                    <Grid xs={12} item>
                        <Button
                            onClick={() => handleUpdateVehicle()}
                            sx={updateVehicleStyles.updateButton}
                            data-testid={'odometer_button'}
                            variant={'primary'}>
                            <Typography sx={updateVehicleStyles.updateText}>{(language as any)[currentLanguage].update}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    )
}

export default UpdateVehicle