import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { digitalReturnsStyles } from '../DigitalReturnsStyles'
import { getFormattedDate } from '../../../utilities/helpers/DateTimeFormatters'
import { returnsListStyles } from './ReturnsListStyles'

const ReturnsList = ({ digitalReturns, returnsToggle }: any) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const [returnsList, setReturnsList] = useState<any[]>([])

  useEffect(() => {
    if (digitalReturns && returnsToggle === 'pending') {
      const filteredReturns = digitalReturns?.filter((item: any) => item.returnStatus !== "Completed")
      setReturnsList(filteredReturns)
    }
    else if(digitalReturns && returnsToggle === 'completed') {
        const filteredReturns = digitalReturns?.filter((item: any) => item.returnStatus === "Completed")
      setReturnsList(filteredReturns)
    }
  }, [digitalReturns, returnsToggle])

  const returnsListGrid: ReactElement = useMemo(() => {

    const redirectToReview = (item: any) => {
      //TO DO for review returns screen
    }

    const QuickSearchToolbar = () => {
      const [searchFilter, setSearchFilter] = useState<string>('')

      return (
        <Box sx={{ p: 0.5, pb: 0 }}>
          <Box style={digitalReturnsStyles.searchBarBox}>
            <GridToolbarQuickFilter
              sx={digitalReturnsStyles.searchBar}
              placeholder={'Search return by return number, customer name, date, or part'}
              quickFilterParser={(searchInput: string) => {
                setSearchFilter(searchInput)
                return searchInput
                  .split('/')
                  .map((value) => value.trim())
                  .filter((value) => value !== '')
              }}
            />
          </Box>
          <Divider />
          {searchFilter ? (
            <Box style={digitalReturnsStyles.searchResultsBox}>
              <Typography style={digitalReturnsStyles.searchResultsFont}>
                Results for "{searchFilter}"
              </Typography>
            </Box>
          ) : null}
        </Box>
      )
    }

    const columns = [
      {
        field: 'returnNumber',
        headerName: 'RETURN #',
        minWidth: 200,
        editable: false
      },
      {
        field: 'customerName',
        headerName: 'CUSTOMER',
        minWidth: 250,
        editable: false,
        filterable: false
      },
      {
        field: 'returnDate',
        headerName: 'DATE RECIEVED',
        minWidth: 200,
        editable: false,
        filterable: false
      },
      {
        field: 'status',
        headerName: 'STATUS',
        minWidth: 200,
        editable: false,
        filterable: false
      },
      {
        field: 'printSlip',
        headerName: '',
        minWidth: 150,
        editable: false,
        filterable: false,
        renderCell: (params: any) => {
          return (
            <Button
              onClick={async (event) => {
                event.stopPropagation()
              }}
              sx={digitalReturnsStyles.disableText}
              data-testid="print_slip">
              Print Return Slip
            </Button>
          )
        }
      }
    ]

    const renderDataGrid = () => {
      return (
        <DataGrid
          getRowId={(row) => row.returnNumber}
          style={{ height: `${windowSize.current[1] - 250}px` }}
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: () => (
              <Stack sx={returnsListStyles.noRows}>
                No Returns Available
              </Stack>
            )
          }}
          disableColumnSelector={true}
          rows={
            returnsList?.map((list) => {
              const row = {
                returnNumber: `${list.returnInvoiceNumber}-${list.returnId}`,
                customerName: list.customerNumber,
                returnDate: getFormattedDate(list.creationTimestamp),
                status: list.returnStatus
              }
              return row
            }) || []
          }
          columns={columns}
          hideFooterSelectedRowCount={true}
          disableVirtualization={process.env.NODE_ENV === 'test'}
          sx={digitalReturnsStyles.gridSize}
          componentsProps={{
            toolbar: {
              showQuickFilter: false,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
          onRowClick={(item) => {
            redirectToReview(item)
          }}
        />
      )
    }
    return renderDataGrid()
  }, [returnsList])

  return (
    <Grid item container>
      <Grid item container xs={12}>
        {returnsListGrid}
      </Grid>
    </Grid>
  )
}

export default ReturnsList
