export const CreateRoutePreviewMapStyles = {
    mapWithLegendContainer: {
      flex: 1,
      width: '100%',
      height: '310px',
      left: '0px',
      top: '-20px',
      //marginBottom: '15px',
    },
    mapContainer: {
      flex: 1,
      width: '100%',
      height: '250px',
      left: '0px',
      top: '-20px',
      //marginBottom: '15px',
    },
    mapCanvas: {
      cursor: 'default'
    },
    RouteMapPreview: {
      top: '0px',
      display: 'flex',
      padding: '10px',
    },
    errorContainer: {
      padding: '5px',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      zIndex: 1, // Make sure it's higher than the map container's z-index
    },
    errorBox: {
      top: '5px', // You can remove this line if not needed
      marginRight: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      zIndex: '999',
      boxShadow: 'none',
      fontFamily: 'Napa Sans',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1.43',
      backgroundColor: 'rgb(253, 237, 237)',
      display: 'flex',
      padding: '6px 16px',
      color: 'rgb(95, 33, 32)'
    },
    errorIcon: {
      marginRight: '10px',
      color: '#d32f2f'
    },
    mapLegend: {
      marginTop: '-10px'
    }
  }