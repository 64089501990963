import axios from 'axios'

type getMapRouteCoordinatesRequest = {
  coordinates: string
}

export class RouteBuilderService {
  static MAPBOX_ROUTES_DIRECTION_ENDPOINT = 'https://api.mapbox.com/directions/v5'
  static MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''
  static STORE_LOCATION_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/napaStores'

  static async getMapRouteCoordinates(request: getMapRouteCoordinatesRequest) {
    try {
      const response = await axios.get(
        `${this.MAPBOX_ROUTES_DIRECTION_ENDPOINT}/mapbox/driving/${request.coordinates}?steps=true&geometries=geojson&access_token=${this.MAPBOX_ACCESS_TOKEN}`
      )
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
