import { createContext, useState, useEffect, ReactElement } from 'react'
import { AuthHelper } from '../helpers/AuthHelper'
import { useNavigate } from 'react-router-dom'
import { useUserAcceptedTerms } from '../../queries/Terms/useUserAcceptedTerms'

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isInitializingCurrentUser: true,
  isInitializingTerms: true,
  signIn: () => {},
  signOut: () => {},
  termsAccepted: undefined,
  refetchTerms: () => {}
})

type AuthContextType = {
  currentUser: CurrentUser | null
  isInitializingCurrentUser: boolean
  signIn: (username: string, password: string) => void
  signOut: () => void
  termsAccepted: boolean | undefined
  isInitializingTerms: boolean
  refetchTerms: () => void
}

export interface CurrentUser {
  username: string
  givenName: string
  familyName: string
  email: string
  defaultStoreNumber: string
  storeList: string[]
  userType: string
}

export const AuthProvider = ({ children }: any): ReactElement => {
  let navigate = useNavigate()

  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
  const [isInitializingCurrentUser, setIsInitializingCurrentUser] = useState<boolean>(true)

  const {
    data: termsAccepted,
    isLoading: isLoadingTerms,
    refetch: refetchTerms
  } = useUserAcceptedTerms(currentUser?.username as string)

  useEffect(() => {
    AuthHelper.currentSignedInUser()
      .then((user) => {
        setCurrentUser(user)
      }).catch((e) => console.error(e.message))
      .finally(() => setIsInitializingCurrentUser(false))
  }, [])

  const signIn = async (username: string, password: string) => {
    try {
      const currentUser: CurrentUser = await AuthHelper.signIn({ username, password })
      setCurrentUser(currentUser)
      navigate('/')
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  const signOut = async () => {
    try {
      await AuthHelper.signOut()
    } catch (error: any) {
      console.error('Error signing out')
    } finally {
      setCurrentUser(null)
      navigate('/')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInitializingCurrentUser,
        isInitializingTerms: isLoadingTerms,
        signOut,
        signIn,
        termsAccepted,
        refetchTerms
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
