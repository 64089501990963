import { Auth } from 'aws-amplify'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { CurrentUser } from '../contexts/AuthContext'
import {getStores} from "../services/StoreService/StoreService";


interface SignInRequest {
  username: string
  password: string
}

export interface UserAuthTokens {
  accessToken: string
  idToken: string
  refreshToken: string
}

export class AuthHelper {
  static async signIn(signInRequest: SignInRequest): Promise<CurrentUser> {
    try {
      //TODO: type responses when we switch to GCP
      const cognitoUser: any = await Auth.signIn({
        username: signInRequest.username,
        password: signInRequest.password
      })

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return Promise.reject({
          message: 'NEW_PASSWORD_REQUIRED',
          user: cognitoUser
        })
      }
      const Stores = await getStores(cognitoUser.username)
      const currentUser = {
        username: cognitoUser.username,
        givenName: cognitoUser.attributes.given_name,
        familyName: cognitoUser.attributes.family_name,
        email: cognitoUser.attributes.email,
        defaultStoreNumber: cognitoUser.attributes['custom:store_number'],
        userType: cognitoUser.attributes['custom:user_type'],
        storeList: Stores
      }

      if (currentUser.userType === 'driver')
        return Promise.reject({
          message: `${currentUser.username} does not have sufficient permissions; please contact your administrator`,
          email: currentUser.email
        })

      return currentUser
    } catch (error: any) {
      switch (error.message) {
        case 'Incorrect username or password.':
          return Promise.reject({
            message: 'Incorrect username and/or password. Try again or Reset your password',
            action: '/ResetPassword'
          })
        case 'User is disabled.':
          return Promise.reject({
            message: 'This account has been disabled. Please see store admin to re-enable it'
          })
        case 'Password attempts exceeded':
          return Promise.reject({
            message: 'Please try again later or click here to reset your password',
            action: '/ForgotPassword'
          })
        case 'New password required':
          return Promise.reject({
            reason: 'NEW_PASSWORD_REQUIRED',
            user: error.user
          })
        case 'Insufficient permissions':
          return Promise.reject({
            message: `${error.username} does not have sufficient permissions; please contact your administrator`
          })
        default:
          return Promise.reject({ message: 'Unknown Service Error' })
      }
    }
  }

  static async forgotPassword(username: string): Promise<void> {
    try {
      await Auth.forgotPassword(username)
    } catch (error: any) {
      return Promise.reject(error.message)
    }
  }

  static async forgotPasswordSubmit(user: any, code: string, newPassword: string) {
    try {
      const result = await Auth.forgotPasswordSubmit(user, code, newPassword)
      return result
    } catch (error: any) {
      return Promise.reject(error.name)
    }
  }

  static async completePasswordChallenge(user: any, newPassword: string) {
    try {
      await Auth.completeNewPassword(user, newPassword)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async currentSignedInUser(): Promise<CurrentUser> {
    try {
      const cognitoUser: any = await Auth.currentAuthenticatedUser()
      const Stores = await getStores(cognitoUser.username)
      const currentUser = {
        username: cognitoUser.username,
        givenName: cognitoUser.attributes.given_name,
        familyName: cognitoUser.attributes.family_name,
        email: cognitoUser.attributes.email,
        defaultStoreNumber: cognitoUser.attributes['custom:store_number'],
        userType: cognitoUser.attributes['custom:user_type'],
        storeList: Stores
      }

      if (currentUser.userType === 'driver')
        return Promise.reject({
          message: `${currentUser.username} does not have sufficient permissions; please contact your administrator`
        })

      return currentUser
    } catch (e) {
      return Promise.reject({
        message: 'No user has been Authenticated'
      })
    }
  }

  static async signOut() {
    try {
      await Auth.signOut()
    } catch (error: any) {
      console.error('Error signing out of cognito')
    }
  }

  static async getUserAuthTokens(): Promise<UserAuthTokens> {
    try {
      const currentSession: CognitoUserSession = await Auth.currentSession()
      return {
        accessToken: currentSession.getAccessToken().getJwtToken(),
        idToken: currentSession.getIdToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken()
      }
    } catch (error: any) {
      return Promise.reject(error.message)
    }
  }
}
