import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { addVehicleStyles } from './addVehicleStyles'
import { MyFleetService } from '../../../../utilities/services/MyFleetService'
import { AuthContext } from '../../../../utilities/contexts/AuthContext'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

const AddVehicle = (
    addVehicleOpen: boolean,
    setAddVehicleOpen: (addVehicleOpen: boolean) => void
) => {
    const { currentStore } = useContext(StoreContext)
    const { currentLanguage } = useContext(LanguageContext)
    const { addSnack } = useContext(SnackbarContext)
    const { currentUser } = useContext(AuthContext)
    const [vehicleName, setVehicleName] = useState<string>('')
    const [year, setYear] = useState<string>('')
    const [yearError, setYearError] = useState<string>('')
    const [make, setMake] = useState<string>('')
    const [makeError, setMakeError] = useState<string>('')
    const [model, setModel] = useState<string>('')
    const [modelError, setModelError] = useState<string>('')
    const [vinNumber, setVinNumber] = useState<string>('')
    const [vinNumberError, setVinNumberError] = useState<string>('')
    const [license, setLicense] = useState<string>('')
    const [licenseError, setLicenseError] = useState<string>('')
    const [odometer, setOdometer] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [vehicleColor, setVehicleColor] = useState<string>('')
    const [vehicleColorError, setVehicleColorError] = useState<string>('')
    const blockInvalidChar = (event: { key: string; preventDefault: () => any }) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

    const validateFields = () => {
        let validate = true;
        if (!vehicleName) {
            setNameError((language as any)[currentLanguage].errorVehicle)
            validate = false
        }
        if (!year) {
            setYearError((language as any)[currentLanguage].errorYear)
            validate = false
        }
        if (!make) {
            setMakeError((language as any)[currentLanguage].errorMake)
            validate = false
        }
        if (!model) {
            setModelError((language as any)[currentLanguage].errorModel)
            validate = false
        }
        if (!vinNumber) {
            setVinNumberError((language as any)[currentLanguage].errorVin)
            validate = false
        }
        if (!license) {
            setLicenseError((language as any)[currentLanguage].errorLicense)
            validate = false
        }
        if (!vehicleColor) {
            setVehicleColorError((language as any)[currentLanguage].errorColor)
            validate = false
        }
        return validate;
    }

    const successModal = () => {
        addSnack({
            severity: 'success',
            message: (language as any)[currentLanguage].successVehicle,
            action: null,
            duration: 3000
        })
    }

    const errorModal = (message: any) => {
        addSnack({
            severity: 'error',
            message: message,
            action: null,
            duration: 3000
        })
    }

    const clearInput = () => {
        setVehicleName('')
        setYear('')
        setMake('')
        setModel('')
        setVinNumber('')
        setLicense('')
        setVehicleColor('')
        setOdometer('')
    }

    const clearErrors = () => {
        setNameError('')
        setYearError('')
        setMakeError('')
        setModelError('')
        setVinNumberError('')
        setLicenseError('')
        setVehicleColorError('')
    }

    const clearDetails = () => {
        setAddVehicleOpen(false)
        clearErrors()
        clearInput()
    }

    const handleAddUser = async () => {
        if (!validateFields()) {
            return false
        }
        else {
            try {
                const vehicleRequest = {
                    make: make,
                    vehicleName: vehicleName,
                    vehicleVin: vinNumber,
                    year: year,
                    model: model,
                    licensePlate: license,
                    color: vehicleColor,
                    lastOdometerReading: odometer,
                    storeNumber: currentStore,
                    createdBy: currentUser?.username,
                    createdDate: new Date().toISOString(),
                    obsoleteRecord: false,
                }
                await MyFleetService.saveNewVehicle(vehicleRequest);
                clearDetails()
                successModal()
            }
            catch (message) {
                errorModal(message)
            }
        }

    }

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
            <Modal
                open={addVehicleOpen}
                onClose={() => clearDetails()}
                sx={addVehicleStyles.addVehicleModal}>
                <Grid container sx={addVehicleStyles.addVehicleModalContainer}>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={addVehicleStyles.modalTitle}>{(language as any)[currentLanguage].addVehicle}</Typography>
                        <IconButton onClick={() => clearDetails()}>
                            <ClearIcon fontSize="medium" />
                        </IconButton>
                    </Grid>
                    <Box sx={addVehicleStyles.inputContainer} width={'100%'}>
                        <Typography sx={addVehicleStyles.addVehicleLabel}>{(language as any)[currentLanguage].vehicle}</Typography>
                        <TextField
                            sx={{ width: '100%' }}
                            inputProps={{ 'data-testid': 'vehicleName' }}
                            type='text'
                            placeholder={`${(language as any)[currentLanguage].vehicleName}*`}
                            error={!!nameError}
                            helperText={nameError}
                            value={vehicleName}
                            onChange={(vehicleName) => setVehicleName(vehicleName.target.value)}
                            onKeyUp={() => setNameError('')}
                        />
                    </Box>
                    <Box sx={addVehicleStyles.inputContainer}>
                        <Typography sx={addVehicleStyles.addVehicleLabel}>{(language as any)[currentLanguage].ymmc}</Typography>
                        <Grid xs={12} spacing={0.5} container>
                            <Grid item xs={3}>
                                <TextField
                                    inputProps={{ 'data-testid': 'year' }}
                                    type='number'
                                    placeholder={`${(language as any)[currentLanguage].year}*`}
                                    error={!!yearError}
                                    helperText={yearError}
                                    value={year}
                                    onKeyDown={blockInvalidChar}
                                    onChange={(year) => setYear(year.target.value)}
                                    onKeyUp={() => setYearError('')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    inputProps={{ 'data-testid': 'make' }}
                                    type='text'
                                    placeholder={`${(language as any)[currentLanguage].make}*`}
                                    error={!!makeError}
                                    helperText={makeError}
                                    value={make}
                                    onChange={(make) => setMake(make.target.value)}
                                    onKeyUp={() => setMakeError('')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    inputProps={{ 'data-testid': 'model' }}
                                    type='text'
                                    placeholder={`${(language as any)[currentLanguage].model}*`}
                                    error={!!modelError}
                                    helperText={modelError}
                                    value={model}
                                    onChange={(model) => setModel(model.target.value)}
                                    onKeyUp={() => setModelError('')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    inputProps={{ 'data-testid': 'color' }}
                                    type='text'
                                    placeholder={`${(language as any)[currentLanguage].color}*`}
                                    error={!!vehicleColorError}
                                    helperText={vehicleColorError}
                                    value={vehicleColor}
                                    onChange={(vehicleColor) => setVehicleColor(vehicleColor.target.value)}
                                    onKeyUp={() => setVehicleColorError('')}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={addVehicleStyles.inputContainer} width={'100%'}>
                        <Typography sx={addVehicleStyles.addVehicleLabel}>{(language as any)[currentLanguage].vin}</Typography>
                        <TextField
                            sx={{ width: '100%' }}
                            inputProps={{ 'data-testid': 'vinNumber' }}
                            type='text'
                            placeholder={(language as any)[currentLanguage].vinNumber}
                            error={!!vinNumberError}
                            helperText={vinNumberError}
                            value={vinNumber}
                            onChange={(vinNumber) => setVinNumber(vinNumber.target.value)}
                            onKeyUp={() => setVinNumberError('')}
                        />
                    </Box>
                    <Box sx={addVehicleStyles.inputContainer} width={'100%'}>
                        <Typography sx={addVehicleStyles.addVehicleLabel}>{(language as any)[currentLanguage].license}</Typography>
                        <TextField
                            sx={{ width: '100%' }}
                            inputProps={{ 'data-testid': 'license' }}
                            type='text'
                            placeholder={`${(language as any)[currentLanguage].licensePlate}*`}
                            error={!!licenseError}
                            helperText={licenseError}
                            value={license}
                            onChange={(license) => setLicense(license.target.value)}
                            onKeyUp={() => setLicenseError('')}
                        />
                    </Box>
                    <Box sx={addVehicleStyles.inputContainer} width={'100%'}>
                        <Typography sx={addVehicleStyles.addVehicleLabel}>{(language as any)[currentLanguage].odometerOptional}</Typography>
                        <TextField
                            sx={{ width: '100%' }}
                            inputProps={{ 'data-testid': 'odometer' }}
                            type='number'
                            placeholder={(language as any)[currentLanguage].odometerOptional}
                            value={odometer}
                            onKeyDown={blockInvalidChar}
                            onChange={(odometer) => setOdometer(odometer.target.value)}
                        />
                    </Box>
                        <Grid spacing={1} container>
                            <Grid xs={6} item>
                                <Button sx={{ width: '100%' }} variant={'secondary'} onClick={() => {
                                    clearDetails()
                                }} data-testid="cancel_vehicle">
                                    {(language as any)[currentLanguage].cancel}
                                </Button>
                            </Grid>
                            <Grid xs={6} item>
                                <Button sx={{ width: '100%' }} variant={'primary'} onClick={() => handleAddUser()} data-testid="save_vehicle">
                                {(language as any)[currentLanguage].save}
                                </Button>
                            </Grid>
                        </Grid>
                </Grid>
            </Modal >
        </Grid >
    )
}
export default AddVehicle