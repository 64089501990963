import { useContext } from 'react'
import { useQuery } from 'react-query'
import { ReturnsKeys } from '../keys'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import { DigitalReturnsService } from '../../utilities/services/DigitalReturnsService'

export const useDigitalReturnsByStore = () => {
  const { currentStore } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)

  return useQuery(
    [ReturnsKeys.GetReturns, currentStore],
    (context) => DigitalReturnsService.getReturns(context.queryKey[1]),
    {
      enabled: !!currentStore,
      refetchInterval: 10000,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({ severity: 'error', message: 'Error retrieving digital returns', duration: 3000 })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}
