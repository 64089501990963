const LRU = require('lru-cache')
const routeCache = new LRU({ 
  max: 100 // Maximum number of items that the cache can hold
});


export const useCache = () => {
  const setCache = (key: string, value: any) => {
    routeCache.set(key, value);
  }

  const getCache = (key: string) => {
    const cachedDetails = routeCache.get(key);
    if (cachedDetails) {
      return cachedDetails;
    }
  }

  return {
    getCache,
    setCache
  }
}
