import { createTheme } from '@mui/material/styles'
import Colors from './assets/Colors'

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.napaBlue
    },
    common: {
      black: '#0000'
    },
    mode: 'light'
  },
  typography: {
    fontFamily: ["'Napa Sans'"].join(',')
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.napaBlue
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: Colors.napaBlue,
            color: Colors.white,
            borderRadius: 3,
            width: 325,
            height: 44,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: Colors.napaBlue3
            }
          }
        },
        {
          props: { disabled: true },
          style: {
            backgroundColor: Colors.napaGreyDisabled
          }
        },
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: Colors.white,
            color: Colors.napaBlue,
            borderColor: Colors.napaBlue,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 3,
            width: 325,
            height: 44,
            textTransform: 'none'
          }
        }
      ]
    }
  }
})

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true
    secondary: true
  }
}

export default theme
