import Colors from "../../../../assets/Colors";

export const vehicleDetailsStyles = {
  title: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0.48px',
    marginBottom: 0
  },
  editTitle: {
    color: Colors.napaGrey2,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.48px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  gridDetail: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  gridContainer: {
    marginTop: 0,
    paddingInline: 5,
    textAlign: 'left'
  },
  saveButton: {
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'initial',
    color: Colors.napaBlueLink,
    lineHeight: '19.5px'
  },
  backButton: {
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'initial',
    color: Colors.napaGrey2
  },
  viewReport: {
    textTransform: 'initial',
    color: Colors.napaBlueLink
  },
  edit: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0.48px',
    marginBottom: 0,
    borderRadius: '1px',
    width: '363px'
  },
  editText: {
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'initial',
    alignItems: 'center',
    color: Colors.napaBlueLink,
    lineHeight: '19.5px'
  },
  titleText: {
    fontSize: '14px',
    color: Colors.napaGrey
  },
  fontNormal: {
    fontWeight: '400'
  },
  fontBold: {
    fontWeight: '700'
  },
  updatedOdometer: {
    fontSize: '18px',
    color: Colors.black,
    fontWeight: '700',
    marginRight: 2
  },
  bodyTitle:{
    fontSize: '14px',
    color: Colors.black,
    fontWeight: '400',
  },
  bodyText: {
    fontSize: '12px',
    color: Colors.black,
    fontWeight: '400'
  },
  reportHeader: {
    fontSize: '16px',
    color: Colors.napaBlue,
    fontWeight: '700'
  },
  lastInspection: {
    fontSize: '18px',
    color: Colors.black, 
    marginRight: 2,
    fontWeight: '700'
  }
}