import { Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { editAddInvoicesStyles } from '../EditAddInvoices/editAddInvoicesStyles'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

type CancelRouteProps = {
  openCancelRoute: boolean
  setOpenCancelRoute: (openCancelRoute: boolean) => void
  setOpenEditRoute: (openEditRoute: boolean) => void
  routeId: string | undefined
  externalDeliveryId: string | undefined
  clearEditedRouteData: () => void
}

const CancelRoute = ({
  openCancelRoute,
  setOpenCancelRoute,
  setOpenEditRoute,
  routeId,
  externalDeliveryId,
  clearEditedRouteData
}: CancelRouteProps) => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)

  const handleCancelRoute = async () => {
    setOpenCancelRoute(false)
    setOpenEditRoute(false)
    if (routeId) {
      try {
        await DeliveryRouteService.cancelRoute(routeId)
        clearEditedRouteData()
        addSnack({
          severity: 'success',
          message: `${(language as any)[currentLanguage].successRoute} #${routeId} ${
            (language as any)[currentLanguage].hasCancel
          }`,
          action: null,
          duration: 3000
        })
      } catch (e) {
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorCancel,
          duration: 3000
        })
        return Promise.reject(e)
      }
    }
  }

  const handleCancelDoordash = async () => {
    setOpenCancelRoute(false)
    setOpenEditRoute(false)
    if (externalDeliveryId) {
      try {
        const response: any = await DeliveryRouteService.cancelExternalDelivery(externalDeliveryId)
        clearEditedRouteData()
        addSnack({
          severity: 'success',
          message: response.message,
          action: null,
          duration: 3000
        })
      } catch (e: any) {
        var mes = ''
        if (e.message.includes('cancel_not_allowed'))
          mes = (language as any)[currentLanguage].errorCancelling
        else mes = e.message
        addSnack({
          severity: 'error',
          message: mes ? mes : (language as any)[currentLanguage].errorCancel,
          duration: 3000
        })
      }
    }
  }

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <Modal
        open={openCancelRoute}
        onClose={() => setOpenCancelRoute(false)}
        sx={editAddInvoicesStyles.addInvoiceModal}>
        <Grid
          container
          sx={[editAddInvoicesStyles.container, editAddInvoicesStyles.cancelRouteContainer]}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={editAddInvoicesStyles.cancelRouteHeader}>
              {(language as any)[currentLanguage].cancelRoute}
            </Typography>
            <IconButton
              onClick={() => {
                setOpenCancelRoute(false)
              }}>
              <ClearIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Box sx={editAddInvoicesStyles.cancelRouteBody}>
            {(language as any)[currentLanguage].cancelModal}
          </Box>
          <Box sx={editAddInvoicesStyles.saveCancelButtonBox}>
            <Button
              variant={'secondary'}
              onClick={() => {
                setOpenEditRoute(true)
                setOpenCancelRoute(false)
              }}
              sx={{ width: '50%', marginRight: '10px' }}>
              {(language as any)[currentLanguage].no}
            </Button>
            <Button
              onClick={externalDeliveryId ? handleCancelDoordash : handleCancelRoute}
              variant={'primary'}
              sx={{ width: '50%' }}>
              {(language as any)[currentLanguage].yes}
            </Button>
          </Box>
        </Grid>
      </Modal>
    </Grid>
  )
}
export default CancelRoute
