import Colors from '../../assets/Colors'

const styles = {
  forgotPasswordContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '90vh'
  },
  forgotPasswordStack: {
    justifyContent: 'center',
    alignItems: 'center',
    spacing: 3,
    width: 325
  },
  logo: {
    width: 125,
    height: 125
  },
  header: {
    fontFamily: 'sans-serif',
    fontWeight: '700',
    color: Colors.napaGrey2,
    fontSize: 20,
    lineHeight: '28px'
  },
  subheader: {
    fontFamily: 'sans-serif',
    fontWeight: '400',
    color: Colors.napaGrey3,
    fontSize: 14,
    align: 'center',
    lineHeight: '19.5px'
  },
  submitContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  input: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.napaGrey,
    lineHeight: '22px'
  },
  submit: {
    width: 296,
    height: 48,
    backgroundColor: Colors.napaBlue
  }
}

export default styles
