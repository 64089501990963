import Colors from '../../../../assets/Colors'

export const manualAddInvoiceStyles = {
  manualAddInvoiceModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  modalContainer: {
    width: '395px',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px'
  },
  header: {
    height: '32px',
    fontWeight: '700',
    fontSize: '25px',
    letterSpacing: '0.4px',
    marginBottom: '15px',
    color: Colors.napaBlue
  }
}
