export const getFormattedDate = (timestamp: number) => {
  return new Date(timestamp).toLocaleTimeString([], {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

export const msToTime = (
  duration: number,
  timeFormat: 'hh-mm-ss' | 'mm-ss' | 'ss' = 'hh-mm-ss'
) => {
  let seconds: string | number = Math.floor((duration / 1000) % 60),
    minutes: string | number = Math.floor((duration / (1000 * 60)) % 60),
    hours: string | number = Math.floor(duration / 3600000)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  switch (timeFormat) {
    case 'hh-mm-ss':
      return hours + ':' + minutes + ':' + seconds
    case 'mm-ss':
      return minutes + ':' + seconds
    case 'ss':
      return seconds
    default:
      return hours + ':' + minutes + ':' + seconds
  }
}

export const getStringToDate = (dateString: string) => {
  const dateParts = dateString.split('T')[0].split('-')
  const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
  return formattedDate
}

export const getStringToTime = (dateString: string) => {
  return dateString ? new Date(dateString).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }) : ''
}

export const convertEpochToTimezoneFormat = (millisec: number, etaInMinutes?: number) => {
  let date = new Date(millisec)
  if (etaInMinutes)
    date.setMinutes(date.getMinutes() + (etaInMinutes ?? 0));
  let hours = date.getHours() // gives the value in 24 hours format
  let AmOrPm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12 || 12
  let minutes = '0' + date.getMinutes()
  let finalTime = hours + ':' + minutes.substr(-2) + ' ' + AmOrPm
  return finalTime
}
