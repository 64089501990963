import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { useQuery } from 'react-query'
import { DeliveryRouteKeys } from '../keys'
import { DeliveryRouteService } from '../../utilities/services/DeliveryRouteService'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useDeliveryRoutesByStore = (
  startDate?: any,
  endDate?: any,
  enabled: boolean = true,
  withPhotoPOD: boolean = false
) => {
  const { currentStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  return useQuery(
    [DeliveryRouteKeys.GetStoreDeliveryRoutes, currentStore, startDate, endDate, withPhotoPOD],
    (context) =>
      DeliveryRouteService.getRoutes(
        context.queryKey[1],
        context.queryKey[2],
        context.queryKey[3],
        context.queryKey[4]
      ),
    {
      enabled: !!currentStore && enabled,
      refetchInterval: 15000,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({
            severity: 'error',
            message: (language as any)[currentLanguage].errorDeliveryRoutes,
            duration: 3000
          })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}
