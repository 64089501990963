import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import {
  AppUser,
  UserManagementService
} from '../../../../../utilities/services/UserManagementService'
import { SnackbarContext } from '../../../../../utilities/contexts/SnackbarContext'
import ClearIcon from '@mui/icons-material/Clear'
import disableUserStyles from './disableUserStyles'
import { useUsersByStore } from '../../../../../queries/Users/useUsersByStore'
import language from '../../../../../language/language'
import { LanguageContext } from '../../../../../utilities/contexts/LanguageContext'

type UserDetailsCardProps = {
  selectedDriver: AppUser | null
  openDisable: boolean
  openEnable: boolean
  setOpenDisable: Dispatch<SetStateAction<boolean>>
  setOpenEnable: Dispatch<SetStateAction<boolean>>
}

const DisableUser = ({ selectedDriver, openDisable, setOpenDisable, openEnable, setOpenEnable  }: UserDetailsCardProps) => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { refetch: refetchUsers } = useUsersByStore()

  const disableUser = async () => {
    if (selectedDriver) {
      try {
        //@ts-ignore
        await UserManagementService.disableUser(selectedDriver.username)
        addSnack({
          severity: 'success',
          message: (language as any)[currentLanguage].userDisable,
          action: null,
          duration: 3000
        })
        handleClose()
      } catch (e: any) {
        console.error((language as any)[currentLanguage].errorDisable, e.message)
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorDisable,
          action: null,
          duration: 3000
        })
      } finally {
        refetchUsers()
      }
    }
  }

  const enableUser = async () => {
    if (selectedDriver) {
      try {
        //@ts-ignore
        await UserManagementService.enableUser(selectedDriver.username)
        addSnack({
          severity: 'success',
          message: (language as any)[currentLanguage].userEnabled,
          action: null,
          duration: 3000
        })
        handleClose()
      } catch (e: any) {
        console.error((language as any)[currentLanguage].errorEnable, e.message)
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorEnable,
          action: null,
          duration: 3000
        })
      } finally {
        refetchUsers()
      }
    }
  }
  
  const handleClose = () => {
    setOpenDisable(false)
    setOpenEnable(false)
  }

  return (
    <Grid>
      <Modal
        sx={disableUserStyles.disableUserModal}
        open={openEnable ? openEnable: openDisable}
        onClose={handleClose}
        aria-labelledby="disable-user-modal"
      >
        <Grid container sx={disableUserStyles.disableModalContainer}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={disableUserStyles.modalTitle}>{ openEnable ? `${(language as any)[currentLanguage].enable}` : `${(language as any)[currentLanguage].disable}`} User</Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ marginY: '10px' }}>
            {(language as any)[currentLanguage].enabled} { openEnable ? `enable` : `disable` } {selectedDriver?.givenName}{' '}
              {selectedDriver?.familyName}?
            </Typography>
          </Grid>
          <Box sx={{ marginY: '10px', width: '100%' }}>
            <Button
              sx={disableUserStyles.cancelYesButton}
              onClick={handleClose}
              variant={'secondary'}
            >
              {(language as any)[currentLanguage].cancel}
            </Button>
            <Button
              sx={disableUserStyles.cancelYesButton}
              onClick={openEnable ? enableUser: disableUser}
              variant={'primary'}
            >
              {(language as any)[currentLanguage].yes}
            </Button>
          </Box>
        </Grid>
      </Modal>
    </Grid>
  )
}

export default DisableUser
