import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import { useQuery } from 'react-query'
import { MyFleetKeys } from '../keys'
import { MyFleetService } from '../../utilities/services/MyFleetService'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useInspectionByVehicle = (vehicleVin: string) => {
  const { currentStore } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  
  return useQuery(
    [MyFleetKeys.GetInspectionReport, currentStore, vehicleVin],
    (context) => MyFleetService.getInspectionReports(context.queryKey[1], context.queryKey[2]),
    {
      enabled: !!currentStore,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({ severity: 'error', message: (language as any)[currentLanguage].errorInspectionReport, duration: 3000 })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}
