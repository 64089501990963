import { Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { manualAddInvoiceStyles } from './manualAddInvoiceStyles'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

type ManualAddInvoiceProps = {
  manualAddInvoiceOpen: boolean
  setManualAddInvoiceOpen: any
}

const ManualAddInvoice = ({
  manualAddInvoiceOpen,
  setManualAddInvoiceOpen
}: ManualAddInvoiceProps) => {
  const { currentStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  //useRef here for focus on input element
  const textFieldRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (manualAddInvoiceOpen) {
      textFieldRef.current?.focus()
    }
  }, [manualAddInvoiceOpen])

  const validateInvoice = () => {
    setErrorMessage('')
    if (!invoiceNumber) {
      setErrorMessage((language as any)[currentLanguage].validInvoice)
    }
  }
  const manualAddInvoiceNumber = async () => {
    validateInvoice()
    if (invoiceNumber) {
      try {
        await DeliveryRouteService.manualAddInvoice(currentStore, invoiceNumber)
        setManualAddInvoiceOpen(false)
        addSnack({
          severity: 'success',
          message: `${(language as any)[currentLanguage].invoice}${invoiceNumber} ${
            (language as any)[currentLanguage].invoiceAdded
          }`,
          duration: 3000
        })
        setInvoiceNumber('')
      } catch (e: any) {
        setManualAddInvoiceOpen(false)
        addSnack({
          severity: 'error',
          message: `${e.message}`,
          duration: 3000
        })
        setInvoiceNumber('')
      }
    }
  }
  const handleDisable = () => {
    return invoiceNumber.length <= 0
  }

  return (
    <Modal
      keepMounted={true}
      open={manualAddInvoiceOpen}
      onClose={() => setManualAddInvoiceOpen(false)}
      sx={manualAddInvoiceStyles.manualAddInvoiceModal}>
      <Grid container sx={manualAddInvoiceStyles.modalContainer}>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '10px'
          }}>
          <Typography sx={manualAddInvoiceStyles.header}>
            {(language as any)[currentLanguage].addInvoice}
          </Typography>
          <IconButton
            onClick={() => {
              setManualAddInvoiceOpen(false)
              setInvoiceNumber('')
            }}>
            <ClearIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <TextField
          inputRef={textFieldRef}
          sx={{ width: '363px', marginBottom: '20px' }}
          inputProps={{ 'data-testid': 'invoiceNumber' }}
          type="text"
          placeholder="123456"
          error={!!errorMessage}
          helperText={errorMessage}
          value={invoiceNumber}
          onChange={(invoiceNumber) => setInvoiceNumber(invoiceNumber.target.value)}
        />
        <Button
          sx={{ width: '363px' }}
          autoFocus={false}
          disabled={handleDisable()}
          onClick={() => {
            manualAddInvoiceNumber()
          }}
          variant={'primary'}>
          {(language as any)[currentLanguage].add}
        </Button>
      </Grid>
    </Modal>
  )
}
export default ManualAddInvoice
