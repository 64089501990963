import * as React from 'react'
import { useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useNavigate } from 'react-router-dom'
import NapaLogo from '../../assets/napa_logo.png'
import { signInStyles } from './signInStyles'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import { navigationBarStyles as styles } from '../../components/NavigationBar/navigationBarStyles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SupportedLanguage } from '../../language/LanguageTypes'

type menuItem = {
  menuName: string | null | undefined
  icon: JSX.Element
  onClick?: (arg0: any) => void
}

const SignIn = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState('')
  const [showDisabled, setShowDisabled] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('')
  const { signIn } = useContext(AuthContext)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null)
  const { isFrenchEnabled } = useFlags()

  let navigate = useNavigate()

  const validateEmail = (email: string): boolean => {
    setEmail(email)
    if (!email) {
      setEmailErrorMessage((language as any)[currentLanguage].username)
      setShowDisabled(true)
      return false
    } else {
      setEmailErrorMessage('')
      setShowDisabled(false)
    }
    return true
  }

  const validatePassword = (password: string): boolean => {
    setPassword(password)
    if (!password) {
      setPasswordErrorMessage((language as any)[currentLanguage].password)
      setShowDisabled(true)
      return false
    } else {
      setPasswordErrorMessage('')
      setShowDisabled(false)
    }
    return true
  }

  const handleLogin = async () => {
    try {
      await signIn(email, password)
    } catch (error: any) {
      addSnack({
        severity: 'error',
        message: error.message,
        action: error.action ? { prompt: 'RESET', callback: () => navigate(error.action) } : null,
        duration: 3000
      })
    }
  }

  const catchReturn = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleLogin().then()
  }

  const handleLangChange = (value: SupportedLanguage) => {
    setAnchorElLanguage(null)
    changeLanguage(value)
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  const menus = [
    {
      menuName: '',
      icon: (
        <>
          <LanguageIcon sx={styles.navBarMenuIcon} />
          <KeyboardArrowDownSharpIcon sx={styles.navBarMenuIcon} />
        </>
      ),
      onClick: handleLanguageChange
    }
  ]

  const LanguageChange = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        defaultValue={currentLanguage}
        onClose={handleCloseLanguage}
        sx={styles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleLangChange('en')}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => handleLangChange('fr')}>
          <ListItemText>French</ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isFrenchEnabled &&
          menus.map((menu: menuItem) => (
            <Button
              sx={{ ...styles.navbarButtonStyle, fontWeight: 'bold', textTransform: 'none' }}
              startIcon={menu.icon}
              onClick={menu.onClick}>
              {menu.menuName}
            </Button>
          ))}
      </div>
      <LanguageChange />
      <div style={signInStyles.main}>
        <img src={NapaLogo} alt="NAPA Logo" style={signInStyles.logo} />
        <h1 style={signInStyles.signInHeader}>{(language as any)[currentLanguage].signIn}</h1>

        <div style={signInStyles.emailTextField}>
          <TextField
            autoFocus={true}
            id="field-em"
            style={signInStyles.fieldEmail}
            inputProps={{ 'data-testid': 'email' }}
            type="text"
            label={`${(language as any)[currentLanguage].usernameText}*`}
            variant="outlined"
            error={!!emailErrorMessage}
            helperText={emailErrorMessage}
            value={email}
            onChange={(email) => validateEmail(email.target.value)}
            onKeyDown={catchReturn}
          />
        </div>
        <div style={signInStyles.passwordTextField}>
          <TextField
            id="field-pw"
            style={signInStyles.fieldPassword}
            inputProps={{ 'data-testid': 'password' }}
            label={`${(language as any)[currentLanguage].passwordText}*`}
            variant="outlined"
            value={password}
            type={showPassword ? 'text' : 'password'}
            error={!!passwordErrorMessage}
            helperText={passwordErrorMessage}
            onChange={(password) => validatePassword(password.target.value)}
            onKeyDown={catchReturn}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div style={signInStyles.checkboxContainer}>
          <Checkbox inputProps={{ 'aria-label': 'controlled' }} style={signInStyles.checkbox} />
          {(language as any)[currentLanguage].keepMeSignIn}
          <Link
            href="#"
            style={signInStyles.forgotPassword}
            onClick={() => navigate('/ForgotPassword')}>
            {(language as any)[currentLanguage].forgotYourPassword}
          </Link>
        </div>
        <div>
          <Button
            disableFocusRipple
            variant="primary"
            data-testid="sign-in"
            onClick={handleLogin}
            disabled={showDisabled}>
            {(language as any)[currentLanguage].signIn}
          </Button>
        </div>
        <div style={signInStyles.newUserLabel}>
          <Divider style={signInStyles.newUserLabel}>
            {(language as any)[currentLanguage].newNAPA}
          </Divider>
        </div>
        <div>
          <Button
            variant="secondary"
            data-testid="registration-btn"
            onClick={() => navigate('/Registration')}>
            {(language as any)[currentLanguage].completeRegistration}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SignIn
