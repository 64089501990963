import Colors from '../../../../assets/Colors'

export const invoiceActionStyles = {
    invoiceContent: {
        padding: '16px'
    },
    modalTitle: {
        color: Colors.napaBlue,
        fontWeight: '400',
        fontSize: '22px',
        fontFamily: 'NAPA SANS'
    },
    deliveryTitle: {
        fontWeight: '400',
        fontSize: '14px',
        margin: '15px 0 5px',
        color: Colors.black,
    },
    containerMargin: {
        marginTop: '15px'
    },
    renderButtons: {
        width: '100%',
        borderRadius: '5px',
        marginTop: '5px',
        fontSize: '16px',
        fontWeight: '700'
    },
    label: {
        color: Colors.black,
        fontSize: '14px',
        fontWeight: '400'
    },
    inputContainer: {
        padding: '10px'
    },
    formControl: {
        '& .MuiFormControlLabel-label':
        {
            fontSize: '14px',
            fontWeight: '400'
        }
    },
    radioButton: {
        padding: '5px 10px'
    },
    titleMargin: {
        marginTop: '5px'
    }
}
