import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import { useQuery } from 'react-query'
import { InvoiceKeys } from '../keys'
import { DeliveryRouteService } from '../../utilities/services/DeliveryRouteService'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useInvoicesByStore = (startDate?: any,endDate?:any, enabled:boolean = true) => {
  const { currentStore } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)

  return useQuery(
    [InvoiceKeys.GetInvoicesByStore, currentStore, startDate, endDate],
    (context) => DeliveryRouteService.getInvoicesByStore(context.queryKey[1], context.queryKey[2],
      context.queryKey[3]),
    {
      enabled: !!currentStore && enabled,
      refetchInterval: 15000,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({ severity: 'error', message: (language as any)[currentLanguage].errorInvoice, duration: 3000 })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}
