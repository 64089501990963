import Colors from '../../../../../assets/Colors'

const styles = {
  modalTitle: {
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '0.52px',
    color: Colors.napaBlue
  },
  disableUserModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  disableModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '24px',
    position: 'relative',
    width: '445px',
    background: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px'
  },
  deleteUser: {
    textTransform: 'none',
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    color: Colors.napaBlueLink,
    marginY: '5px'
  },
  cancelYesButton: {
    width: '45%',
    marginX: '5px',
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '18px',
    letterSpacing: '0.32px'
  }
}

export default styles
