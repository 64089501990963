import Colors from '../../../../assets/Colors'

export const addVehicleStyles = {
  modalTitle: {
    height: '32px',
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '32px',
    letterSpacing: '0.4px',
    color: Colors.napaBlue
  },
  addVehicleModalContainer: {
    width: '832px',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    overflow: 'auto',
    height: '617px',
  },
  addVehicleModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  addVehicleTextField: {
    width: '363px',
    marginBottom: '10px'
  },
  addVehicleLabel: {
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '14px',
    letterSpacing: '0.36px',
    marginBottom: '5px',
    color: Colors.black
  },
  inputContainer: {
    paddingY: '5px'
  },
  errorText: {
    color: '#D32F2F',
    fontSize: 12,
    marginLeft: '14px',
    marginBottom: '5px',
    marginTop: '3px'
  }
}