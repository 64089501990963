import { StyleSheet } from '@react-pdf/renderer'
import Colors from '../../../../assets/Colors'

export const pdfStyles = StyleSheet.create({
  page: {
    // flexDirection: 'column'
  },
  section: {
    marginHorizontal: 50,
    marginVertical: 10
    // padding: 10
  },
  routeReview: {
    color: Colors.napaBlue,
    fontSize: 16,
    textAlign: 'left',
    marginBottom: 20
  },
  subtext: {
    color: Colors.napaBlack,
    lineHeight: 1.3,
    fontSize: 14
  },
  stopStyle: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.napaGrey,
    borderRadius: '2'
  },
  invoiceNumberStyle: {
    fontSize: 12,
    marginVertical: 10
  },
  partsRowStyle: {
    flexDirection: 'row',
    fontSize: 11
  },
  borderTopStyle: {
    borderTopStyle: 'solid',
    borderTopColor: Colors.napaGrey,
    borderTopWidth: 1
  },
  partsCellStyle: {
    flex: 0.25,
    textAlign: 'left',
    padding: 5
  },
  storeNameStyle: {
    fontSize: 13,
    color: Colors.napaBlue,
    lineHeight: 1.3
  },
  storeAddressStyle: {
    lineHeight: 1.3,
    fontSize: 12,
    color: Colors.napaGrey
  },
  deliveryStatusStyle: {
    fontSize: 11,
    color: Colors.napaGreenDark
  },
  signatureContainer: {
    padding: 5,
    flexDirection: 'row',
    fontSize: 12,
    marginTop: 10,
    lineHeight: 1.5
  },
  signatureCaptureName: {
    flex: 0.5
  },
  signatureCaptureImage: {
    flex: 0.5,
    alignItems: 'center'
  },
  signatureImageStyle: {
    width: 100
  }
})
