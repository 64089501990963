import { useQuery } from 'react-query'
import { TermsKeys } from '../keys'
import { TermsAndConditionsService } from '../../utilities/services/TermsAndConditionsService'
import { useContext } from 'react'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useUserAcceptedTerms = (currentUser: string) => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  
  return useQuery(
    [TermsKeys.GetTerms, currentUser],
    (context) => TermsAndConditionsService.getUserAcceptedLatestTerms(context.queryKey[1]),
    {
      enabled: !!currentUser,
      onError: (_err) => {
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorUserTnC,
          duration: 3000
        })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}
