import React, { Fragment, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Container,
  MenuItem,
  ListItemText,
  Divider,
  Menu
} from '@mui/material'
import logo from '../../assets/napa_logo.png'
import { AuthHelper } from '../../utilities/helpers/AuthHelper'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import forgotPasswordStyles from './forgotPasswordStyles'
import Colors from '../../assets/Colors'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { navigationBarStyles } from '../../components/NavigationBar/navigationBarStyles'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SupportedLanguage } from '../../language/LanguageTypes'

type menuItem = {
  menuName: string | null | undefined
  icon: JSX.Element
  onClick?: (arg0: any) => void
}

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('')
  // const [message, setMessage] = useState<string>('');
  // const [severityType, setSeverityType] = useState<string>('');
  // const [disabled, setDisabled] = useState<boolean>(true);
  // const [show, setShow] = useState<boolean>(false);
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null)
  const { isFrenchEnabled } = useFlags()

  let navigate = useNavigate()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (email) {
      try {
        await AuthHelper.forgotPassword(email)
        addSnack({
          severity: 'success',
          message: (language as any)[currentLanguage].mailSentToMail,
          action: null,
          duration: 3000
        })
        navigate('/ResetPassword', { state: { email } })
        // setDisabled(true)
      } catch (e) {
        addSnack({
          severity: 'error',
          message: 'Unknown service error, please try again.',
          action: null,
          duration: 3000
        })
      }
    } else {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].errorEmail,
        action: null,
        duration: 3000
      })
    }
  }

  const handleBlur = () => {
    if (!email) {
      // setShow(true)
      // setMessage('Please enter a valid email address.')
      // setSeverityType('error')
      // setDisabled(true)
    } else {
      // setDisabled(false)
      // setShow(false)
    }
  }

  const handleLangChange = (value: SupportedLanguage) => {
    setAnchorElLanguage(null)
    changeLanguage(value)
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  const menus = [
    {
      menuName: '',
      icon: (
        <>
          <LanguageIcon sx={navigationBarStyles.navBarMenuIcon} />
          <KeyboardArrowDownSharpIcon sx={navigationBarStyles.navBarMenuIcon} />
        </>
      ),
      onClick: handleLanguageChange
    }
  ]

  const LanguageChange = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        defaultValue={currentLanguage}
        onClose={handleCloseLanguage}
        sx={navigationBarStyles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleLangChange('en')}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => handleLangChange('fr')}>
          <ListItemText>French</ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isFrenchEnabled &&
          menus.map((menu: menuItem) => (
            <Button
              sx={{
                ...navigationBarStyles.navbarButtonStyle,
                fontWeight: 'bold',
                textTransform: 'none'
              }}
              startIcon={menu.icon}
              onClick={menu.onClick}>
              {menu.menuName}
            </Button>
          ))}
      </div>
      <LanguageChange />
      <Container sx={forgotPasswordStyles.forgotPasswordContainer}>
        <Stack style={forgotPasswordStyles.forgotPasswordStack}>
          <Box>
            <img src={logo} alt={'napa logo'} style={forgotPasswordStyles.logo} />
          </Box>
          <Box>
            <Typography
              variant={'h4'}
              style={forgotPasswordStyles.header}
              data-testid="forgot-text">
              {(language as any)[currentLanguage].forgotYourPassword}
            </Typography>
          </Box>
          <Box>
            <Typography style={forgotPasswordStyles.subheader}>
              {(language as any)[currentLanguage].associatedUser}
            </Typography>
          </Box>
          <Box
            width={{ xs: '300px', sm: '350px' }}
            component={'form'}
            noValidate
            onSubmit={handleSubmit}
            sx={forgotPasswordStyles.submitContainer}>
            <TextField
              autoComplete="false"
              data-testid="email"
              type={'email'}
              label={`${(language as any)[currentLanguage].usernameText}*`}
              value={email}
              id="email"
              margin="normal"
              inputProps={{ style: forgotPasswordStyles.input }}
              InputLabelProps={{ style: { color: Colors.napaGrey } }}
              sx={{
                width: '296px',
                height: '48px',
                mb: '15px',
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': { borderColor: '#001489' }
                },
                '& .MuiOutlinedInput-root:hover': {
                  '& > fieldset': { borderColor: '#001489' }
                }
              }}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleBlur}
            />
            <Button
              type={'submit'}
              variant={'contained'}
              sx={forgotPasswordStyles.submit}
              disabled={email ? false : true}
              data-testid="send-button">
              {(language as any)[currentLanguage].sendEmail}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  )
}

export default ForgotPassword
