import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, Paper, Stack, Typography, FormControl,
   ListItemText, IconButton, Checkbox } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { AppUser, UserManagementService } from '../../../../utilities/services/UserManagementService'
import userDetailsStyles from './userDetailsStyles'
import TextField from '@mui/material/TextField'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'
import { AuthContext } from '../../../../utilities/contexts/AuthContext'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'

type UserDetailsCardProps = {
  selectedDriver: AppUser,
  setDisableUserModal: (openDisableUserModal: boolean) => void
  setEnableUserModal: (openEnableUserModal: boolean) =>  void
}

const UserDetailsCard = ({ selectedDriver, setDisableUserModal,setEnableUserModal  }: UserDetailsCardProps) => {
  const { refetch: refetchUsers } = useUsersByStore()
  const [editable, setEditable] = useState<boolean>(false)
  const [phone, setPhone] = useState('')
  const { addSnack } = useContext(SnackbarContext)
  const { currentUser } = useContext(AuthContext)
  const { currentLanguage } = useContext(LanguageContext)
  const [givenNameErrorMessage, setGivenNameErrorMessage] = useState<string>('')
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('')
  const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState('')
  const [userTypeErrorMessage, setUserTypeErrorMessage] = useState<string>('')
  const [defaultStoreErrorMessage, setDefaultStoreErrorMessage] = useState<string>('')
  const [givenName, setGivenName] = useState<string>('')
  const [familyName, setFamilyName] = useState<string>('')
  const [userType, setUserType] = useState<string>('')
  const [defaultStoreNumber, setDefaultStoreNumber] = useState<string>('')
  const [assignedStoreNumbers, setAssignedStoreNumbers] = useState<string[]>([])
  const [uniqueStoreOptions, setUniqueStoreOptions] = useState<string[]>([])

  useEffect(() => {
    setEditable(false)
    if(currentUser){
      const StoreGroup = selectedDriver?.storeList?.concat(currentUser.storeList)
      const uniqueStoreGroup = StoreGroup?.filter((item, index) => StoreGroup.indexOf(item) === index);
      setUniqueStoreOptions(uniqueStoreGroup ? uniqueStoreGroup : [])
    }
    setAssignedStoreNumbers(selectedDriver?.storeList ? selectedDriver?.storeList.filter((stores) => stores !== selectedDriver?.defaultStoreNumber) : [])
  }, [selectedDriver, currentUser])

  const validatePhone = () => {
    if (!phone || phone === '+' || phone === '+1') {
      return true
    }
    if (phone.length !== 12) {
      setPhoneErrorMessage((language as any)[currentLanguage].invalidPhone)
      return false
    }
    return true
  }

  const handleCancel = () => {
    setEditable(false)
    setPhone('')
    setGivenName('')
    setFamilyName('')
    setUserType('')
    setDefaultStoreNumber('')
  }

  const handleEdit = () => {
    setEditable(true)
    if(selectedDriver){
      selectedDriver.phoneNumber ? setPhone(selectedDriver?.phoneNumber) : setPhone('')
      setGivenName(selectedDriver.givenName)
      setFamilyName(selectedDriver.familyName)
      setUserType(selectedDriver?.userType)
      setDefaultStoreNumber(selectedDriver?.defaultStoreNumber)
    }
  }

  const handleStoreChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event
    if (value[value.length - 1] === 'all') {
      setAssignedStoreNumbers(assignedStoreNumbers.length === uniqueStoreOptions.length ? [] : uniqueStoreOptions)
      return
    }
    setAssignedStoreNumbers(typeof value === 'string' ? value.split(',') : value)
  }

  const validateFields = () => {
    let validate = true
    if (!givenName) {
      setGivenNameErrorMessage((language as any)[currentLanguage].fName)
      validate = false
    }
    if (!familyName) {
      setFamilyNameErrorMessage((language as any)[currentLanguage].lName)
      validate = false
    }
    if (!validatePhone()) {
      validate = false
    }
    if (!userType) {
      validate = false
      setUserTypeErrorMessage('User Type is required.')
    }
    if (!defaultStoreNumber) {
      validate = false
      setDefaultStoreErrorMessage('Default Store Number is required.')
    }
    return validate
  }
  const updateDriver = async () => {
    setPhoneErrorMessage('')
    setFamilyNameErrorMessage('')
    setGivenNameErrorMessage('')
    setUserTypeErrorMessage('')
    setDefaultStoreErrorMessage('')
    if (!validateFields()) {
      return false
    }
    if (selectedDriver) {
      try {
        await UserManagementService.updateUser(
          {
            email: selectedDriver.email,
            familyName: familyName,
            givenName: givenName,
            username: selectedDriver.username,
            //@ts-ignore
            phoneNumber: phone.length === 12 ? phone : null,
            defaultStoreNumber: defaultStoreNumber,
            storeList: selectedDriver.storeList && selectedDriver.storeList?.length > 0 ? assignedStoreNumbers.concat(defaultStoreNumber): assignedStoreNumbers,
            userType: userType
          },
          //@ts-ignore
          selectedDriver.id
        )
        addSnack({
          severity: 'success',
          message: 'Success!',
          action: null,
          duration: 3000
        })
        refetchUsers()
      } catch (e: any) {
        console.error((language as any)[currentLanguage].errorUpdateDriver, e.message)
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorUpdateDriver,
          action: null,
          duration: 3000
        })
      }
    }
  }

  const handleCountryChange = (value: any) => {
    let validPhone = value.replace(/[{()}-]/g, '')
    validPhone = validPhone.replaceAll(' ', '')
    setPhone(validPhone)
  }
const AssignedStoreSelect = () => {
  const isAllSelected = uniqueStoreOptions.length > 0 && assignedStoreNumbers.length === uniqueStoreOptions.length
  return (
    <Box mt={2} mb={1}>
      <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].assignedStore}</Typography>
      <FormControl fullWidth>
          <Select
          id="other-store-select"
          inputProps={{ 'data-testid': 'other-store-select' }}
          value={assignedStoreNumbers}
          multiple
          displayEmpty
          sx={userDetailsStyles.dropDownField}
          onChange={(event) => { handleStoreChange(event)}}
          renderValue={(selected) => selected.length === 0 ? (
            <em>{(language as any)[currentLanguage].selectStoreTitle}</em>
          ) : (
            `${selected.filter((stores) => stores !== selectedDriver?.defaultStoreNumber).join(', ')}`
          ) }
          error={!!defaultStoreErrorMessage}>
        <MenuItem value="all">
        <Checkbox
          checked={isAllSelected}
          indeterminate={assignedStoreNumbers.length > 0 && assignedStoreNumbers.length < uniqueStoreOptions?.length}
        />
        <ListItemText primary={(language as any)[currentLanguage].selectAll} />
      </MenuItem>
      {uniqueStoreOptions
        ?.filter((options) => options !== selectedDriver?.defaultStoreNumber)
        .map((store) => (
          <MenuItem key={store} value={store}>
            <Checkbox checked={assignedStoreNumbers.indexOf(store) > -1} />
            <ListItemText primary={`#${store}`} />
          </MenuItem>
        ))}
        </Select>
      </FormControl>
      <Typography sx={userDetailsStyles.phoneErrorText}>{defaultStoreErrorMessage}</Typography>
    </Box>
  )
}

  return (
    <Paper sx={userDetailsStyles.container}>
      <Stack>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Typography style={userDetailsStyles.driverTypography}>
            {editable ? `${(language as any)[currentLanguage].editUser}` : `${selectedDriver?.givenName} ${selectedDriver?.familyName}`}
          </Typography>
          { !editable ? <Button sx={userDetailsStyles.editButton} onClick={handleEdit}>
          {(language as any)[currentLanguage].edit}
          </Button> : <IconButton onClick={handleCancel}>
              <ClearIcon fontSize="medium" />
            </IconButton>}
        </Grid>

        {editable ?
          <Box mt={2} mb={1}>
            <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].nameText}</Typography>
            <TextField
              sx={{ width: '100%', marginY: '5px' }}
              inputProps={{ 'data-testid': `given-name` }}
              type='text'
              label={`${(language as any)[currentLanguage].firstName}*`}
              error={!!givenNameErrorMessage}
              helperText={givenNameErrorMessage}
              value={givenName}
              onChange={(event) => {
                setGivenName(event.target.value)
              }}
            />
            <TextField
              sx={{ width: '100%', marginY: '5px' }}
              label={`${(language as any)[currentLanguage].lastName}*`}
              inputProps={{ 'data-testid': `family-name` }}
              type='text'
              error={!!familyNameErrorMessage}
              helperText={familyNameErrorMessage}
              value={familyName}
              onChange={(event) => {
                setFamilyName(event.target.value)
              }}
            />
          </Box>
          : null
        }

        <Box mt={2} mb={1}>
          <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].email}</Typography>
          <Typography style={userDetailsStyles.infoText}>{selectedDriver?.email}</Typography>
        </Box>
        <Box mt={2} mb={1}>
          <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].usernameText}</Typography>
          <Typography style={userDetailsStyles.infoText}>{selectedDriver?.username}</Typography>
        </Box>
        <Box mt={2} mb={1}>
          <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].phoneNumber}</Typography>
          {!editable ? (
              <Typography
                style={userDetailsStyles.infoText}>{selectedDriver.phoneNumber ? selectedDriver.phoneNumber : 'No phone number on record'}</Typography>
            ) :
            <>
              <MuiPhoneNumber
                defaultCountry={'us'}
                error={!!phoneErrorMessage}
                onChange={handleCountryChange}
                inputProps={{ 'data-testid': 'phonenumber' }}
                onlyCountries={['us', 'ca']}
                type={'text'}
                disableAreaCodes
                InputProps={{ disableUnderline: true }}
                value={phone}
                sx={{
                  ...userDetailsStyles.phoneNumberField,
                  borderColor: phoneErrorMessage ? '#D32F2F' : '#bfbfbf'
                }}
              />
              <Typography sx={userDetailsStyles.phoneErrorText}>{phoneErrorMessage}</Typography>
            </>}
        </Box>
        <Box mt={2} mb={1}>
          <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].userTypeTitle}*</Typography>
          {!editable ? (
              <Typography
                style={userDetailsStyles.infoText}>{selectedDriver?.userType}</Typography>
            ) :
            <>
              <FormControl fullWidth>
                <Select
                id="user-type-select"
                inputProps={{ 'data-testid': 'user-type-select' }}
                value={userType}
                required={true}
                sx={userDetailsStyles.dropDownField}
                onChange={ (event) => { setUserType(event.target.value) }}
                error={!!userTypeErrorMessage}>
                <MenuItem value={'driver'}>{(language as any)[currentLanguage].driver}</MenuItem>
                <MenuItem value={'admin'}>{(language as any)[currentLanguage].admin}</MenuItem>
              </Select>
            </FormControl>
            <Typography sx={userDetailsStyles.phoneErrorText}>{userTypeErrorMessage}</Typography>
          </>}
        </Box>
        { !editable ? 
            assignedStoreNumbers.length > 0 &&
            <Box mt={2} mb={1}>
              <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].assignedStore}</Typography>
              {assignedStoreNumbers.map((storeGroup, idx) => {
                return (
                  <Typography style={userDetailsStyles.infoText} key={idx}>#{storeGroup}</Typography>
                )})}
            </Box> : <AssignedStoreSelect />
          }
        <Box mt={2} mb={1}>
          <Typography style={userDetailsStyles.infoHeader}>{(language as any)[currentLanguage].store}*</Typography>
          {!editable ? (
              <Typography
                style={userDetailsStyles.infoText}>#{selectedDriver?.defaultStoreNumber}</Typography>
            ) :
            <>
              <FormControl fullWidth>
                <Select
                id="default-store-select"
                inputProps={{ 'data-testid': 'default-store-select' }}
                value={defaultStoreNumber}
                required={true}
                sx={userDetailsStyles.dropDownField}
                onChange={ (event) => { setDefaultStoreNumber(event.target.value) }}
                error={!!defaultStoreErrorMessage}>
                {currentUser?.storeList?.sort().map((store) => (
                <MenuItem key={store} value={store}>
                  <ListItemText primary={`#${store}`} />
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            <Typography sx={userDetailsStyles.phoneErrorText}>{defaultStoreErrorMessage}</Typography>
          </>}
        </Box>
        {editable ? (
          <>
          <Grid>
            <Box sx={{ marginY: '20px' }}>
              <Button
                onClick={handleCancel}
                sx={userDetailsStyles.cancelSaveButton}
                variant={'secondary'}
              >
                {(language as any)[currentLanguage].cancel}
              </Button>
              <Button
                onClick={updateDriver}
                sx={userDetailsStyles.cancelSaveButton}
                variant={'primary'}
              >
                {(language as any)[currentLanguage].save}
              </Button>
            </Box>
          </Grid>
          { selectedDriver?.enabled ? <Button sx={userDetailsStyles.disableUser} onClick={() => setDisableUserModal(true)}>
          {(language as any)[currentLanguage].disableUser}
          </Button>: <Button sx={userDetailsStyles.disableUser} onClick={() => setEnableUserModal(true)}>
          {(language as any)[currentLanguage].enableUser}
          </Button> }
          </>
        ) : null}
      </Stack>
    </Paper>
  )
}

export default UserDetailsCard
