import Colors from '../../../../assets/Colors'

export const completedRouteStyles = {
  routeReviewTypography: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '22px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS',
    padding: '0px 10px 16px 10px',
    display: 'flex'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px 0px 10px',
  },
  paddingZero: {
    paddingTop: 0
  },
  thirdPartyETA: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '19.5px',
    fontFamily: 'NAPA SANS',
    paddingBottom: '8px',

  },
  totalStops: {
    color: Colors.napaBlack,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS'
  },
  invoiceDeliveryAddress: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoiceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '12px'
  },
  removeInvoiceType: {
    color: Colors.napaBlueLink,
    fontSize: '14px',
    paddingRight: '3px',
    marginTop: '2px'
  },
  driversFormGrid: {
    display: 'flex',
  },
  driversForm: {
    m: 1,
    width: {
      xs: '120px',
      sm: '160px',
      md: '370px'
    },
  },
  driverType: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'NAPA SANS',
    paddingBottom: '5px'
  },
  headerTitle: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  invoiceAddressLineType: {
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoice: {
    border: '1px solid rgb(193,193,193)',
    paddingLeft: 10,
    marginBottom: '25px',
    padding: '6px',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-20px'
  },
  returnStore: {
    background: Colors.napaGreyDisabled,
    padding: '6px',
    border: '1px solid rgb(193,193,193)',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-24px'
  },
  travelTime: {
    color: Colors.napaBlack,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS'
  },
  dialogActions: {
    padding: '16px',
    marginTop: '-20px'
  },
  externalDialogbuttons: {
    padding: '16px 0px'
  },
  addInvoicesButton: {
    color: Colors.napaBlueLink,
    textTransform: 'none',
    marginRight: '50px'
  },
  removeButtonBox: {
    display: 'flex',
    justifyContent: 'row',
    paddingRight: '10px'
  },
  invoiceNumber: {
    color: Colors.napaGrey2,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  RouteMapPreview: {
    top: '0px',
    display: 'flex',
    padding: '10px',
  },
  notesTitle: {
    color: Colors.black,
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  delayWarning: {
    display: 'block',
    padding: '8px',
    border: '1px solid #FCEDEE',
    background: '#FCEDEE',
    color: 'black'
  },
  delayTitle: {
    color: Colors.napaRed3,
    fontWeight: '700',
    fontSize: '14px',
  },
  delayContent: {
    fontWeight: '700',
    fontSize: '14px',
    color: Colors.napaGrey2
  },
  delayNotes: {
    fontSize: '12px',
    color: Colors.napaGrey2,
    marginLeft: '27px'
  },
  backToStoreDelay: {
    display: 'block',
    padding: '8px',
    border: '1px solid #FCEDEE',
    background: '#FCEDEE',
    color: 'black',
    marginBottom: '10px'
  },
  stickyBottom: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: Colors.white, 
    padding: '10px'
  },
  accordionSummary: {
    height: '45px', 
    padding: '0 8px' 
  }
}
