import { createContext, useState, useContext } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useMyFleetsByStore } from '../../queries/MyFleets/useMyFleetsByStore'
import VehicleList from './components/VehicleList/VehicleList'
import AddVehicle from './components/AddVehicle/AddVehicle'
import { MyFleetStyles } from './myFleetStyles'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const MyFleetContext = createContext<MyFleetContextType>({
  setSelectedVehicle: () => {},
  selectedVehicle: ''
})

type MyFleetContextType = {
  selectedVehicle: any
  setSelectedVehicle: any
}

const MyFleet = () => {
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null)
  const [addVehicleOpen, setAddVehicleOpen] = useState<boolean>(false)
  const [openVehicleDetails, setOpenVehicleDetails] = useState<boolean>(false)
  const { data: allVehicles } = useMyFleetsByStore()
  const { currentLanguage } = useContext(LanguageContext)

  return (
    <MyFleetContext.Provider
      value={{
        selectedVehicle,
        setSelectedVehicle
      }}>
      {AddVehicle(addVehicleOpen, setAddVehicleOpen)}
      <Grid container spacing={2} sx={MyFleetStyles.gridContainer}>
        {!openVehicleDetails && (
          <Grid item xs={12} sx={MyFleetStyles.gridItem}>
            <Grid xs={8} sx={MyFleetStyles.gridMyFleet} item>
              <Typography data-testid="my_fleet" sx={MyFleetStyles.myFleetTitle}>
              {(language as any)[currentLanguage].myFleet}
              </Typography>
            </Grid>
            <Grid xs={4} sx={MyFleetStyles.addVehicleGrid} item>
              <Button
                variant="primary"
                onClick={() => setAddVehicleOpen(true)}
                sx={MyFleetStyles.addVehicleButton}
                data-testid="add_vehicle">
                <Typography sx={MyFleetStyles.addVehicleText}>{(language as any)[currentLanguage].addVehicle}</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
        <VehicleList
          setOpenVehicleDetails={setOpenVehicleDetails}
          allVehicles={allVehicles?.vehicles}
          openVehicleDetails={openVehicleDetails}></VehicleList>
      </Grid>
    </MyFleetContext.Provider>
  )
}

export default MyFleet
