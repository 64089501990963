import Colors from "../../../../assets/Colors";


export const VehicleListStyles = {
    searchBarBox: {
        display: 'flex',
        marginBottom: 15
    },
    searchBar: {
        width: '100%',
        marginTop: 3
    },
    searchResultsBox: {
        display: 'flex',
        justifyContent: 'initial',
        marginBlock: 10
    },
    searchResultsFont: {
        fontFamily: 'NAPA SANS',
        fontSize: '14px',
        color: Colors.napaGrey
    },
    gridSize: {
        border: 0,
        '& .MuiDataGrid-columnHeaders': {
            fontSize: '12px',
            fontFamily: '400',
            lineHeight: '15px',
            letterSpacing: '0.42px',
            color: '#707070'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '14px',
            color: '#33333',
            lineHeight: '22px',
            letterSpacing: '0.48px'
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
            color: '#001489',
            fontWeight: 'bolder'
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent:focus': {
            fontWeight: 'bolder'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
                '&:nth-of-type(2n-1)': { backgroundColor: '#F4F4F4' },
                '&:nth-of-type(2n-1):hover': { backgroundColor: '#E5E7F3' },
                '&:nth-of-type(2n):hover': { backgroundColor: '#E5E7F3' }
            }
        },
        '& .MuiDataGrid-sortIcon': {
            color: '#001489',
            outline: 'none'
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: '#E5E7F3'
        }
    },
    disableText: {
        color: Colors.napaBlueLink,
        textTransform: 'none'
    }
}
