import Colors from '../../assets/Colors'

export const styles = {
  mapContainer: {
    flex: 1,
    width: '100%',
    left: '0px',
    top: '0px',
    height: 'calc(100vh - 64px)'
  },
  initialAvatar: {
    backgroundColor: '#001489'
  },
  avatar: {
    backgroundColor: 'white',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: 'black'
  },
  mapOverlay: {
    position: 'fixed',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.black,
    color: Colors.white,
    width: '50%',
    height: '100px',
    left: "25%",
    top: '25%',
    opacity: 0.77,
    borderRadius: '5px',
  },
  noActiveRoutesText1: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '700',
    letterSpacing: '0.32px',
    paddingBottom: '8px'
  },
  noActiveRoutesText2: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  mapOverlayText: {
    fontSize: 22,
    fontWeight: 500
  },
  driverInitialMarker: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%', // Border radius should be 50% for a circle
    border: '2px solid #ffffff',
    color: Colors.white,
    letterSpacing: '0.14px',
    fontSize: '18px',
  },
  driverAvatar: {
    width: 45,
    height: 45,
    fontSize: 19,
    fontFamily: 'Napa Sans',
    color: 'white',
  }
}