export const styles = {
  mapContainer: {
    flex: 1,
    width: '100%',
    height: '400px',
    left: '0px',
    top: '0px',
    marginBottom: '15px',
  },
  mapCanvas: {
    cursor: 'default'
  }
}
