import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { UserManagementService } from '../../utilities/services/UserManagementService'
import { useQuery } from 'react-query'
import { UserKeys } from '../keys'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useUsersByStore = () => {
  const { currentStore } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  
  return useQuery(
    [UserKeys.GetUsers, currentStore],
    (context) => UserManagementService.getUsers(context.queryKey[1]),
    {
      enabled: !!currentStore,
      onError: (_err) => {
        addSnack({ severity: 'error', message: (language as any)[currentLanguage].errorUsers, duration: 3000 })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500,
      staleTime: 3000
    }
  )
}
