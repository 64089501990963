import React, { useContext, useState } from 'react'
import { Button, Dialog, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ClearIcon from '@mui/icons-material/Clear'
import language from '../../../../language/language'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { invoiceActionStyles } from './InvoiceActionStyles'

enum ActionType {
    remove = 'remove',
    markAsDelivered = 'markAsDelivered'
}

enum RemoveType {
    canceledReason = 'Customer canceled',
    systemReason = 'Phone / system issue',
    pickUpReason = 'Picked up in-store'
}

enum DeliveredType {
    salesReason = 'Management / salesperson delivered',
    untrainedReason = 'Untrained courier / driver'
}

type InvoiceActionProps = {
    openInvoiceActions: boolean
    invoiceNumber: string | undefined
    invoiceDateTime: number | undefined
    setOpenInvoiceActions: (openAddInvoices: boolean) => void
    setOpenEditInvoice: (value: boolean) => void,
    refetchInvoices: () => {}
    actionType: ActionType
}

const InvoiceAction = ({
    openInvoiceActions,
    invoiceNumber,
    invoiceDateTime,
    setOpenInvoiceActions,
    setOpenEditInvoice,
    refetchInvoices,
    actionType
}: InvoiceActionProps) => {
    const { currentLanguage } = useContext(LanguageContext)
    const { currentStore } = useContext(StoreContext)
    const { addSnack } = useContext(SnackbarContext)

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [reason, setReason] = useState<string>('')

    const handleClose = () => {
        setOpenInvoiceActions(false)
        setFirstName('')
        setLastName('')
        setReason('')
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReason((event.target as HTMLInputElement).value)
    }

    const isDisabled = () => !(reason.trim() && (actionType === ActionType.markAsDelivered ? firstName.trim() && lastName.trim() : true))

    const closeOnSuccess = () => {
        setOpenInvoiceActions(false)
        setOpenEditInvoice(false)
    }

    const handleAction = async () => {
        const request = {
            nssaInvoiceStatus: actionType === ActionType.remove ? 'DELETED' : 'MANUALLY_DELIVERED',
            reason: reason,
            ...(actionType === ActionType.markAsDelivered && { deliveredBy: `${firstName} ${lastName}` })
        }
        try {
            await DeliveryRouteService.updateDeliveryStatus(request, currentStore, invoiceNumber, invoiceDateTime)
            closeOnSuccess()
            handleClose()
            addSnack({
                severity: 'success',
                message: `${(language as any)[currentLanguage].successInvoice}${invoiceNumber} ${actionType === ActionType.remove ? (language)[currentLanguage].invoiceRemoved : (language)[currentLanguage].invoiceDelivered}`,
                action: null,
                duration: 3000
            })
        }
        catch (error) {
            addSnack({
                severity: 'error',
                message: actionType === ActionType.remove ? (language as any)[currentLanguage].errorRemovingInvoice : (language as any)[currentLanguage].invoiceFailed,
                action: null,
                duration: 3000
            })
        }
    }

    const title = actionType === ActionType.remove ? language[currentLanguage].removeInvoice : language[currentLanguage].markDelivered
    const message = actionType === ActionType.remove ? language[currentLanguage].removeInvoicemsg : language[currentLanguage].invoiceDeliveryMsg
    const buttonText = actionType === ActionType.remove ? language[currentLanguage].remove : language[currentLanguage].markDelivered
    const reasonObject = actionType === ActionType.remove ? Object.entries(RemoveType) : Object.entries(DeliveredType)

    return (
        <Dialog open={openInvoiceActions} onClose={() => handleClose()} maxWidth='xs'>
            <Box sx={invoiceActionStyles.invoiceContent}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography sx={invoiceActionStyles.modalTitle}>{title}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            onClick={() => {
                                handleClose()
                            }}>
                            <ClearIcon fontSize='medium' />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box sx={invoiceActionStyles.titleMargin}>
                    <Typography sx={invoiceActionStyles.label}>{message}</Typography>
                </Box>
                <Box sx={invoiceActionStyles.containerMargin}>
                    <FormControl>
                        <FormLabel style={invoiceActionStyles.label}>{language[currentLanguage].reason}</FormLabel>
                        <RadioGroup
                            aria-labelledby='invoiceReason'
                            value={reason}
                            onChange={handleChange}>
                            {reasonObject.map(([reasonKey, reasonValue]) => (
                                <FormControlLabel
                                    key={reasonKey}
                                    data-testid={`radioReason-${reasonKey}`}
                                    sx={invoiceActionStyles.formControl}
                                    value={reasonValue}
                                    control={<Radio size='small' style={invoiceActionStyles.radioButton} />}
                                    label={(language as any)[currentLanguage][reasonKey]}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                {actionType === ActionType.markAsDelivered && (
                    <>
                        <Typography sx={invoiceActionStyles.deliveryTitle}>{language[currentLanguage].deliveredBy}</Typography>
                        <Grid item container xs={12} spacing={0.5}>
                            <Grid item xs={6}>
                                <TextField
                                    inputProps={{ 'data-testid': 'firstName' }}
                                    type='text'
                                    placeholder={language[currentLanguage].firstName}
                                    onChange={(fName) => setFirstName(fName.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    inputProps={{ 'data-testid': 'lastName' }}
                                    type='text'
                                    placeholder={language[currentLanguage].lastName}
                                    onChange={(lName) => setLastName(lName.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                <Box sx={invoiceActionStyles.containerMargin}>
                    <Button
                        variant={'secondary'}
                        data-testid='cancel'
                        onClick={() => {
                            handleClose()
                        }}
                        sx={invoiceActionStyles.renderButtons}>
                        {language[currentLanguage].cancel}
                    </Button>
                    <Button
                        disabled={isDisabled()}
                        data-testid={actionType === ActionType.remove ? 'removeInvoice' : 'markDelivered'}
                        onClick={async () => {
                            await handleAction()
                            refetchInvoices()
                        }}
                        variant={'primary'}
                        sx={invoiceActionStyles.renderButtons}>
                        {buttonText}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default InvoiceAction
