import Colors from '../../../../assets/Colors'
const styles = {
    mapButton : {
        width: '136px',
        height: '48px',
        border: '1px solid #ADADAD',
        backgroundColor: Colors.white,
        color: '#ADADAD',
        fontFamily: 'NAPA SANS', // Change font family
        fontWeight: 'bold',
        fontColor: Colors.napaBlue,
        fontSize: '16px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: Colors.white, // Change background color on hover
        },
        '&:disabled': { // Styles for disabled button
            backgroundColor: '#e5e7f3', // Change background color when disabled
            color: Colors.napaBlue,
            border: `1px solid ${Colors.napaBlue}`,
            '& .MuiSvgIcon-root': { // Styles for the icon when disabled
                color: Colors.napaBlue, // Change icon color when disabled
            },
        },
    }
}

export default styles