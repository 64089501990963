const termsAndConditionsStyles = {
  headerContainer: {
    backgroundColor: 'white',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  container: {
    borderColor: '#D6D6D6',
    borderWidth: '1px',
    borderStyle: 'solid',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  headerText: {
    height: '28px',
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '28px',
    display: 'flex',
    color: '#001489',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  subHeaderText: {
    height: '15px',
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    color: '#858585'
  },
  tcP: {
    marginTop: 1.3,
    marginBottom: 1.3,
    fontSize: 12,
    color: '#333333',
    whiteSpace: 'pre-wrap'
  },
  tcL: {
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
    color: '#333333'
  },
  tcContainer: {
    backgroundColor: 'white',
    contentContainerStyle: {
      flexGrow: 1,
      justifyContent: 'space-between'
    }
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '20px'
  },
  termsHeaderBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px 8px',
    width: '100%',
    height: '60px',
    background: '#001489'
  },
  headerBarTypography: {
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '26.8192px',
    color: '#FFFFFF'
  },
  napaLogo: {
    width: 45,
    padding: 5
  },
  acceptButton: {
    padding: '12px 20px',
    width: '395px',
    background: '#001489',
    fontFamily: 'NAPA SANS',
    borderRadius: '3px',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.32px',
    color: '#FFFFFF',
    textTransform: 'none',
    marginX: '10px'
  },
  declineButton: {
    padding: '12px 20px',
    width: '395px',
    fontWeight: '700',
    fontFamily: 'NAPA SANS',
    height: '48px',
    background: '#FFFFFF',
    border: '1px solid #001489',
    borderRadius: '3px',
    textTransform: 'none',
    marginX: '10px'
  }
}

export default termsAndConditionsStyles
