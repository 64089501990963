import Colors from '../../assets/Colors'

const styles = {
  gridContainer: {
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center' as const,
    marginBottom: '8px'
  },
  header: {
    lineHeight: '38px',
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '32px',
    letterSpacing: '0.48px',
  },
  addImportButtonContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right'
  }
}

export default styles
