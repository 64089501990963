const statusOrder: {[key: string]: number} = {
  'created': 1,
  'pending': 2,
  'in-progress': 3,
  'complete': 4
};

export const customQuickSort = (arr: any): any => {
  if (arr.length <= 1) {
    return arr;
  }

  const pivot = arr[0];
  const left = [];
  const right = [];

  for (let i = 1; i < arr.length; i++) {
    if (statusOrder[arr[i].routeStatus] < statusOrder[pivot.routeStatus]) {
      left.push(arr[i]);
    } else {
      right.push(arr[i]);
    }
  }

  return [
    ...customQuickSort(left),
    pivot,
    ...customQuickSort(right),
  ];
}