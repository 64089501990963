import axios from 'axios'
import { AuthHelper } from '../helpers/AuthHelper'

export class PowerbiReportsService {
    static RETURN_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/reports/getReportInfo'

    static async getPowerBiReports(userName:string|undefined): Promise<any> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            params: {
                userName : userName
            },
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        } 
        try {
            const response = await axios.get<any>(
                this.RETURN_ENDPOINT,
                config
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
