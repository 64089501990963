import Colors from '../../../../assets/Colors'

export const editAddInvoicesStyles = {
  gridSize: {
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      fontSize: '12px',
      fontFamily: '400',
      lineHeight: '15px',
      letterSpacing: '0.42px',
      color: '#707070'
    },
    '& .MuiDataGrid-cell': {
      fontSize: '14px',
      color: '#33333',
      lineHeight: '22px',
      letterSpacing: '0.48px'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
      color: '#001489',
      fontWeight: 'bolder'
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent:focus': {
      fontWeight: 'bolder'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row': {
        '&:nth-of-type(2n-1)': { backgroundColor: '#F4F4F4' },
        '&:nth-of-type(2n-1):hover': { backgroundColor: '#E5E7F3' },
        '&:nth-of-type(2n):hover': { backgroundColor: '#E5E7F3' }
      }
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#001489',
      outline: 'none'
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#E5E7F3'
    }
  },
  searchIcon: {
    color: '#001489',
    paddingX: '10px'
  },
  searchBarBox: {
    display: 'flex',
    marginBottom: 15
  },
  searchBar: {
    width: '100%',
    marginTop: 3
  },
  searchResultsBox: {
    display: 'flex',
    justifyContent: 'initial',
    marginBlock: 10
  },
  searchResultsFont: {
    fontFamily: 'NAPA SANS',
    fontSize: '14px',
    color: Colors.napaGrey
  },
  addInvoiceHeader: {
    height: '32px',
    fontWeight: '700',
    fontSize: '25px',
    letterSpacing: '0.4px',
    marginBottom: '10px',
    color: Colors.napaBlue
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px'
  },
  addInvoiceContainer: {
    width: '700px',
    padding: '16px',
  },
  cancelRouteContainer: {
    width: '500px',
    padding: '24px'
  },
  addInvoiceModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  saveCancelButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  cancelRouteHeader: {
    lineHeight: '24px',
    fontWeight: '700',
    fontSize: '18px',
    marginBottom: '20px',
    color: Colors.napaBlue
  },
  cancelRouteBody: {
    lineHeight: '20px',
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: '20px',
    color: Colors.napaGrey4,
    width: '100%' 
  }
}