import Colors from "../../assets/Colors"

export const MyFleetStyles = {
  myFleetTitle: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '32px',
    lineHeight: '38px',
    letterSpacing: '0.48px',
    marginBottom: 0
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  gridMyFleet: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  addVehicleGrid: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'right'
  },
  gridContainer: {
    marginTop: 0,
    paddingInline: 5
  },
  addVehicleButton: {
    width: '194px',
    color: Colors.napaBlue,
    backgroundColor: Colors.napaBlue
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContent: {
    paddingBottom: '8px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogAction: {
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogButtons: {
    width: '194px'
  },
  doneButton: {
    width: '100%'
  },
  dialogTitle: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS'
  },
  addVehicleText: {
    color: Colors.white
  }
}
