import Colors from "../../../../assets/Colors";

export const AutoDispatchDetailsStyles = {
  headerTitle: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  renderAddressBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0'
  },
  notesTitle: {
    fontSize: '12px',
    fontWeight: '700'
  },
  notesContent: {
    fontSize: '12px',
    fontWeight: '400',
    marginLeft: '2px'
  },
  renderInvoiceBox: {
    display: 'flex',
    paddingTop: '12px'
  },
  invoiceDeliveryAddress: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '22px',
    fontFamily: 'NAPA SANS'
  },
  invoiceAddressLineType: {
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '19.5px',
    fontFamily: 'NAPA SANS'
  },
  title: {
    fontSize: '14px',
    fontWeight: '400'
  },
  description: {
    fontSize: '14px',
    fontWeight: '700',
    marginLeft: '2px'
  },
  autoDispatchTimeGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  renderInvoice: {
    border: '1px solid rgb(193,193,193)',
    paddingLeft: 10,
    marginBottom: '25px',
    padding: '6px',
    borderRadius: '5px'
  }
}
