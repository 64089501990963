import { AuthHelper } from '../helpers/AuthHelper'
import axios from 'axios'

export type TermsHistory = {
  username: string
  creationTimestamp?: number
  accepted: boolean
  timestamp: number
  version: number
}

export type Terms = {
  version: number
  body: string
  creationTimestamp: number
}

export class TermsAndConditionsService {
  //Add terms endpoint to .env
  static TERMS_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/terms'
  static TERMS_ENDPOINT_V2 = process.env.REACT_APP_DELIVERYAPI_URL + '/terms/v2'

  static async getUserAcceptedLatestTerms(username: string): Promise<boolean> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
      const response = await axios.get<boolean>(
        `${this.TERMS_ENDPOINT_V2}/username/${username}`,
        config
      )
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getLatestTerms(): Promise<Terms> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
      const response = await axios.get<Terms>(`${this.TERMS_ENDPOINT_V2}`, config)
      if (response.status === 200) {
        return Promise.resolve(response.data)
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async saveUserTermsHistory(
    username: string,
    termsVersion: number
  ): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }

    const termsHistory: TermsHistory = {
      username: username,
      accepted: true,
      timestamp: new Date().getUTCMilliseconds(),
      version: termsVersion
    }
    try {
      const response = await axios.post<void>(
        `${this.TERMS_ENDPOINT_V2}/termsHistory`,
        termsHistory,
        config
      )
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
