import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import { useQuery } from 'react-query'
import { UserKeys } from '../keys'
import { UserManagementService } from '../../utilities/services/UserManagementService'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useActiveDrivers = () => {
  const { currentStore } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)

  return useQuery(
    [UserKeys.GetActiveDrivers, currentStore],
    (context) => UserManagementService.getActiveDrivers(context.queryKey[1]),
    {
      enabled: !!currentStore,
      refetchInterval: 35000,
      onError: (_err) => {
        addSnack({ severity: 'error', message: (language as any)[currentLanguage].errorActiveDrivers, duration: 3000 })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.response.status === 500
    }
  )
}
