import Colors from '../../assets/Colors'

const styles = {
  whiteboardContainer: {
    margin: 20
  },
  whiteboardHeader: {
    textAlign: 'left' as const,
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '32px',
    letterSpacing: '0.48px',
    marginLeft: '6px',
    marginBottom: 22
  },
  horizontalScrollContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto'
  },
  noActiveRoutesText1: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '700',
    letterSpacing: '0.32px',
    paddingBottom: '8px'
  },
  noActiveRoutesText2: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  WhiteboardMap: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  }
}

export default styles
