import Colors from '../../../../assets/Colors'

const styles = {
  noActiveRoutesCard: {
    border: 'dashed',
    borderWidth: '1px',
    borderColor: '#C1C1C1',
    backgroundColor: '#F4F4F4'
  },
  noActiveRoutesContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noActiveRoutesTypography: {
    color: Colors.napaGrey,
    fontSize: 14,
    lineHeight: '19.5px',
    textAlign: 'center' as const,
    letterSpacing: '0.48px'
  },
  container: {
    paddingX: '16px',
    paddingY: '24px'
  },
  driverItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  driverItem: {
    width: '100%',
    marginTop: '25px',
    marginBottom: '15px'
  },
  driverTypography: {
    color: Colors.napaBlue,
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '.48px',
    textAlign: 'center' as const,
    marginBottom: 5
  },
  currentRouteInfo: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center' as const
  },
  currentRouteHeader: {
    fontWeight: '700',
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: '.36px',
    color: Colors.napaGrey2
  },
  icon: {
    color: Colors.napaBlue
  },
  routeInfoContainer: {
    marginTop: 6,
    marginBottom: 10
  },
  addRouteButton: {
    marginTop: 5,
    color: Colors.napaBlueLink,
    fontSize: 14,
    lineHeight: '22px',
    float: 'left' as const
  },
  infoHeader: {
    fontWeight: '700',
    fontSize: 14,
    lineHeight: '22px',
    paddingBottom: '5px',
    letterSpacing: '0.36px',
    color: Colors.napaGrey2,
    textAlign: 'left' as const
  },
  infoText: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.48px',
    color: Colors.napaGrey4,
    textAlign: 'left' as const
  },
  editButton: {
    width: '27px',
    textTransform: 'none'
  },
  cancelSaveButton: {
    width: '45% ',
    marginX: '5px'
  },
  disableUser: {
    textTransform: 'none',
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#001489',
    marginY: '5px'
  },
  userDisabled: {
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#C1C1C1',
    marginY: '5px'
  },
  phoneErrorText: {
    color: '#D32F2F',
    fontSize: 12,
    textAlign: 'start',
    marginLeft: '14px',
    marginBottom: '5px',
    marginTop: '3px'
  },
  phoneNumberField: {
    border: '1px' , borderStyle: 'solid',
    borderRadius: '4px',
    width: '95%',
    padding: '10px',
    textAlign: 'center',
    svg: {
      height: "20px",
    },
  },
  dropDownField: {
    textAlign: 'left'
  }
}

export default styles
