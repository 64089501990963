import React, { useContext, useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { PowerbiReportsService } from '../../utilities/services/ReportService';
import { AuthContext } from '../../utilities/contexts/AuthContext';
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { useNavigate } from 'react-router-dom'

const Reports = () => {
  const [reportConfig, setReportConfig] = useState({
    embedReports:  [{ id: '', embedUrl: '' }],
    embedToken: { token: '' }
  });
  const { currentUser } = useContext(AuthContext);
  const { currentStore } = useContext(StoreContext)
  const [initialStore] = useState<string>(currentStore);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (currentStore !== initialStore) {
      navigate('/Whiteboard');
    }
  }, [currentStore, initialStore, navigate]);

  useEffect(() => {
    const loadReportConfig = async () => {
      try {
        if (currentUser?.username) {
          const config = await PowerbiReportsService.getPowerBiReports(currentUser.username);
          setReportConfig(config);
        }
      } catch (error) {
        console.error('Failed to fetch report configuration:', error);
      }
    };

    loadReportConfig();
  }, [currentUser?.username]);

  return (
    <div>
      {reportConfig?.embedReports[0]?.id !== '' && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportConfig?.embedReports[0]?.id,
            embedUrl: reportConfig?.embedReports[0]?.embedUrl,
            accessToken: reportConfig?.embedToken?.token,
            tokenType: models.TokenType.Embed,
            settings: {
              filterPaneEnabled: true,
              navContentPaneEnabled: true,
            }
          }}
          cssClassName="powerbi-container"
        />
      )}
    </div>
  );
};

export default Reports;



