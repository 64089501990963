import axios from 'axios'
import { AuthHelper } from '../helpers/AuthHelper'
import { DeliveryRoute } from '../types/DeliveryRouteTypes'

export type AddUserRequest = {
  email: string
  familyName: string
  givenName: string
  phoneNumber?: string
  defaultStoreNumber: string
  userType?: string
  storeList?: string[]
}

export type AppUser = {
  username: string
  defaultStoreNumber: string
  email: string
  givenName: string
  familyName: string
  userType: string
  enabled: boolean
  phoneNumber?: string
  storeList?: string[]
}

export type Driver = AppUser & {
  userStatus: string
  deliveryRoutes: DeliveryRoute[]
  userLocation: any
}

export class UserManagementService {
  static USER_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/users/v2'

  static async getUsers(storeNumber: string): Promise<AppUser[]> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    const response = await axios.get<AppUser[]>(
      `${this.USER_ENDPOINT}/storeNumber/${storeNumber}`,
      config
    )
    return response.data as AppUser[]
  }

  static async getActiveDrivers(storeNumber: string): Promise<Driver[]> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    const response = await axios.get<AppUser[]>(
      `${this.USER_ENDPOINT}/active/v2/storeNumber/${storeNumber}`,
      config
    )
    return response.data as Driver[]
  }

  static async addUser(addUserRequest: AddUserRequest): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
      await axios.post(`${this.USER_ENDPOINT}/adminCreate`, addUserRequest, config)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async disableUser(username: string): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
      await axios.delete(`${this.USER_ENDPOINT}/disable/${username}`, config)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async enableUser(username: string): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
      await axios.post(`${this.USER_ENDPOINT}/enable/${username}`, null, config)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async updateUser(updateUserRequest: AddUserRequest, username: string): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
      await axios.put(`${this.USER_ENDPOINT}/username/${username}`, updateUserRequest, config)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async batchImportUsers(file: File) : Promise<void> {
    //Convert File type to form data
    let formData = new FormData()
    formData.append('file', file)

    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
      headers: {
        Authorization: `Bearer ${userAuthTokens.accessToken}`,
        'Content-Type': ' multipart/form-data'
      }
    }
    try {
      const response:any = await axios.post(
        `${this.USER_ENDPOINT}/batchAdminCreate`,
        formData,
        config
      )
      return response.data.message;
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
