import { Box, Divider, InputBase, Menu, MenuItem, Stack, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import StorefrontIcon from '@mui/icons-material/Storefront'
import Colors from '../../assets/Colors'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { storeSelectorStyles } from './storeSelectorStyles'
import { useLocation, useNavigate } from 'react-router-dom'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

type StoreSelectorProps = {
  anchorEl: HTMLElement | null
  handleClose: () => void
}

const StoreSelector = ({ anchorEl, handleClose }: StoreSelectorProps) => {
  const { currentUser } = useContext(AuthContext)
  const { currentStore, changeStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const [unselectedStores, setUnselectedStores] = useState<string[]>([])
  const searchRef = useRef<HTMLInputElement>()
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentStore && currentUser) {
      setUnselectedStores(currentUser.storeList.filter((store) => store !== currentStore))
    }
  }, [currentStore, currentUser])

  useEffect(() => {
    //TODO: figure out how to autofocus on open
    if (searchRef && searchRef.current) searchRef.current.focus()
  }, [anchorEl])

  const CurrentStoreMenuItem = () => {
    return (
      <Box paddingX={5} paddingY={1} role={'current-store'}>
        <Typography fontSize={18} fontWeight={700}>
        {(language as any)[currentLanguage].storeNumber}{currentStore}
        </Typography>
        <Typography style={storeSelectorStyles.currentStoreTypography}>
          <StorefrontIcon style={{ marginRight: 5 }} />
          {(language as any)[currentLanguage].currentStore}
        </Typography>
      </Box>
    )
  }

  const handleSelectStore = (storeNumber: string) => {
    changeStore(storeNumber)
    handleClose()
    if(currentStore !== storeNumber && location.pathname.includes('VehicleDetails')){
      navigate('MyFleet')
    }
  }

  const StoreSelectorMenuItem = ({ storeNumber }: { storeNumber: string }) => {
    return (
      <MenuItem
        key={storeNumber}
        sx={{ paddingX: 5 }}
        onClick={() => handleSelectStore(storeNumber)}
      >
        <Stack>
          <Typography fontSize={18} fontWeight={700}>
          {(language as any)[currentLanguage].storeNumber}{storeNumber}
          </Typography>
          <Typography style={storeSelectorStyles.selectStoreTypography}>{(language as any)[currentLanguage].selectStore}</Typography>
        </Stack>
      </MenuItem>
    )
  }

  const handleFilter = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (currentUser) {
      setUnselectedStores(
        currentUser.storeList.filter(
          (store) => store.startsWith(event.currentTarget.value) && store !== currentStore
        )
      )
    }
  }

  const renderFilter = () => {
    if (currentUser && currentUser.storeList.length > 4) {
      return [
        <Box
          key={'storeFilter'}
          role={'store-filter'}
          sx={{ display: 'flex', alignItems: 'center', paddingY: 1, paddingX: 2 }}
        >
          <InputBase placeholder={(language as any)[currentLanguage].searchStore} onChange={handleFilter} ref={searchRef} />
          <SearchIcon />
        </Box>,
        <Divider key={'divider'} variant={'middle'} color={Colors.napaBlue2} />
      ]
    }
  }
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      style={{ maxHeight: 400 }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
    >
      <Typography style={{ fontSize: 22, fontWeight: 700, color: Colors.napaBlue, padding: 14 }}>
      {(language as any)[currentLanguage].yourStores}
      </Typography>
      <Divider variant={'middle'} color={Colors.napaBlue2} />
      {renderFilter()}
      <CurrentStoreMenuItem />
      <Divider variant={'middle'} color={Colors.napaGreyDisabled} />
      {unselectedStores?.map((store, idx) => {
        return [
          idx ? <Divider variant={'middle'} color={Colors.napaGreyDisabled} /> : null,
          <StoreSelectorMenuItem storeNumber={store} />
        ]
      })}
    </Menu>
  )
}

export default StoreSelector
