import Colors from "../../../../assets/Colors"

export const updateVehicleStyles = {
    viewReportModalContainer: {
        width: '395px',
        height: '192px',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: Colors.white,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '3px'
    },
    modalTitle: {
        height: '32px',
        fontFamily: 'NAPA SANS',
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '28px',
        letterSpacing: '0.4px',
        color: Colors.napaBlue,
    },
    viewReportModal: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    textInput: {
        width: '363px',
        marginBottom: '10px',
        height: '48px',
        borderColor: '#001489',
        borderRadius: '4px'
    },
    updateButton: {
        width: '100%',
        height: '44px'
    },
    clearButton: {
        color: '#000000',
        alignItems: 'flex-end',

        paddingTop: 0,
        paddingRight: 0
    },
    updateText: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '20px',
        color: Colors.white
    }
}