import Colors from "../../../../assets/Colors";

export const AutoDispatchInfoStyles = {
  autoDispatchInfoContainer: {
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  autoDispatchTimeError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    background: '#FCEDEE',
    padding: '3px 3px',
    marginBottom: '5px',
    fontSize: '14px'
  },
  autoDispatchTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#E5E7F3',
    padding: '3px 3px',
    marginBottom: '5px',
    fontSize: '14px'
  },
  autoDispatchInfoIconError: {
    color: Colors.napaRed3,
    width: '18px',
    height: '18px'
  },
  autoDispatchInfoIcon: {
    color: Colors.napaBlue,
    width: '18px',
    height: '18px'
  },
  startAutoDispatchButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontWeight: '700',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': { color: 'rgba(0, 0, 0, 0.38)' }
  }
}
